<template>
  <div>
    <b-tabs>
      <span v-if="!isEditing" class="text-danger" style="font-size: 12px"
        >Preencha os Dados do Tomador e edite o cadastro para inserir os dados do Segurado/Beneficiário.</span
      >
      <!-- Dados Cadastrais -->
      <validation-observer ref="formDadosCadastraisEnderecoBancarios">
        <b-tab>
          <template #title>
            <feather-icon icon="HomeIcon" />
            <span>Dados Cadastrais</span>
          </template>
          <b-row>
            <b-col md="3">
              <b-form-group label="Foto" label-for="foto" style="margin-bottom: 0">
                <div class="profile-img">
                  <span v-if="!dados.foto" class="b-avatar badge-secondary rounded" style="width: 160px; height: 120px">
                    <feather-icon icon="UserIcon" fill="currentColor" size="80" />
                  </span>
                  <img v-if="dados.foto" :src="dados.foto.foto" class="img-fluid rounded" style="max-height: 120px" />
                  <div style="bottom: -30px; text-align: center; width: 160px; z-index: 1">
                    <b-button
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      variant="flat-success"
                      class="btn-icon rounded-circle"
                      @click="capturarFoto()"
                    >
                      <feather-icon icon="CameraIcon" />
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                      variant="flat-danger"
                      class="btn-icon rounded-circle"
                      @click="limparFoto()"
                    >
                      <feather-icon icon="XSquareIcon" />
                    </b-button>
                  </div>
                </div>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Nome Completo" label-for="nomeCompleto">
                <validation-provider #default="{ errors }" name="Nome Completo" rules="required">
                  <b-form-input
                    id="nomeCompleto"
                    type="text"
                    placeholder="Nome Completo"
                    v-model="dados.nomeCompleto"
                    v-on:keypress="isLetter($event)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Nome Social" label-for="nomeSocial">
                <b-form-input id="nomeSocial" type="text" placeholder="Nome Social" v-model="dados.nomeSocial" />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="CPF" label-for="cpf">
                <validation-provider #default="{ errors }" name="CPF" rules="required">
                  <cleave
                    id="cpf"
                    type="text"
                    placeholder="CPF"
                    v-model="dados.cpf"
                    :raw="false"
                    :options="options.cleaveCPF"
                    class="form-control"
                    @input="removePontos"
                    @blur="validaCamposDocumentos((campo = dados.cpf), (id = 'cpf'))"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Data Nascimento" label-for="dataNascimento">
                <validation-provider #default="{ errors }" name="Data Nascimento" rules="required|data">
                  <cleave
                    id="dataNascimento"
                    class="form-control"
                    type="text"
                    placeholder="DD/MM/AAAA"
                    v-model="dados.dataNascimento"
                    :options="options.cleaveDate"
                    :raw="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="4">
              <b-form-group label="Sexo" label-for="sexo">
                <validation-provider #default="{ errors }" name="Sexo" rules="required">
                  <b-form-select name="sexo" v-model="dados.sexo" :options="sexoOpcoes"></b-form-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Gênero" label-for="genero">
                <validation-provider #default="{ errors }" name="Gênero" rules="required">
                  <b-form-select name="genero" v-model="dados.genero" :options="generoOpcoes"></b-form-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Estado Civil" label-for="estadoCivil">
                <validation-provider #default="{ errors }" name="Estado Civil" rules="required">
                  <b-form-select name="estadoCivil" v-model="dados.estadoCivil" :options="estadoCivilOpcoes"> </b-form-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group label="Nome da Mãe" label-for="nomeMae">
                <validation-provider #default="{ errors }" name="Nome da Mãe" rules="required">
                  <b-form-input
                    id="nomeMae"
                    type="text"
                    placeholder="Nome da Mãe"
                    v-model="dados.nomeMae"
                    v-on:keypress="isLetter($event)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Nome do Pai" label-for="nomePai">
                <b-form-input
                  id="nomePai"
                  type="text"
                  placeholder="Nome do Pai"
                  v-model="dados.nomePai"
                  v-on:keypress="isLetter($event)"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="3">
              <b-form-group label="Nacionalidade" label-for="nacionalidade">
                <validation-provider #default="{ errors }" name="Nacionalidade" rules="required">
                  <b-form-select
                    name="nacionalidade"
                    v-model="dados.nacionalidade"
                    :options="nacionalidade"
                    label="nome"
                    value-field="value"
                    text-field="text"
                    v-on:change="limparValores"
                  ></b-form-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="3" v-if="dados.nacionalidade === BRAZIL_COUNTRY_CODE">
              <b-form-group label="UF Naturalidade" label-for="ufNaturalidade">
                <validation-provider #default="{ errors }" name="UF Naturalidade" rules="required">
                  <b-form-select
                    id="ufNaturalidade"
                    name="ufNaturalidade"
                    v-model="dados.ufNaturalidade"
                    :options="ufOpcoes"
                    v-on:change="limparValoresEstado"
                  ></b-form-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="6" v-if="dados.nacionalidade === BRAZIL_COUNTRY_CODE">
              <b-form-group label="Naturalidade" label-for="naturalidade">
                <validation-provider #default="{ errors }" name="Naturalidade" rules="required">
                  <v-select-pt
                    name="naturalidade"
                    v-model="dados.naturalidade"
                    :options="cidadeNaturalidade"
                    :reduce="(option) => option.nome"
                    label="nome"
                  ></v-select-pt>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="9" v-if="dados.nacionalidade != BRAZIL_COUNTRY_CODE">
              <b-form-group label="Naturalidade" label-for="naturalidade">
                <validation-provider #default="{ errors }" name="Naturalidade" rules="required">
                  <b-form-input
                    id="naturalidade"
                    type="text"
                    placeholder="Naturalidade"
                    v-model="dados.naturalidade"
                    v-bloqueioCaractereMask="{ tamanho: 70 }"
                    v-on:keypress="isLetter($event)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="3">
              <b-form-group label="RG Nº" label-for="rgNumero">
                <validation-provider #default="{ errors }" name="RG Nº" rules="required">
                  <cleave
                    id="rgNumero"
                    type="text"
                    placeholder="RG Nº"
                    v-model="dados.rgNumero"
                    :raw="false"
                    :options="options.cleaveRG"
                    class="form-control"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Data Emissão" label-for="rgDataEmissao">
                <validation-provider
                  #default="{ errors }"
                  name="Data Emissão"
                  :rules="{
                    required: true,
                    data: true,
                    dataPassada: {
                      value: dados.rgDataEmissao,
                      dataParametro: dados.dataNascimento,
                      stringParametro: 'data de nascimento',
                    },
                  }"
                >
                  <cleave
                    id="rgDataEmissao"
                    class="form-control"
                    type="text"
                    placeholder="DD/MM/AAAA"
                    v-model="dados.rgDataEmissao"
                    :options="options.cleaveDate"
                    :raw="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="RG Órgão Emissor" label-for="rgOrgaoEmissor">
                <validation-provider #default="{ errors }" name="RG Órgão Emissor" rules="required">
                  <b-form-input id="rgOrgaoEmissor" type="text" placeholder="RG Órgão Emissor" v-model="dados.rgOrgaoEmissor" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="UF de Expedição do RG" label-for="rgUF">
                <validation-provider #default="{ errors }" name="UF de Expedição do RG" rules="required">
                  <b-form-select id="rgUF" name="rgUF" v-model="dados.rgUF" :options="ufOpcoes"></b-form-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="3">
              <b-form-group label="Telefone" label-for="telefone">
                <validation-provider #default="{ errors }" name="Telefone" rules="required|telFixoAndCelular">
                  <cleave
                    id="telefone"
                    type="text"
                    placeholder="Telefone"
                    v-model="dados.telefone"
                    :raw="false"
                    :options="options.cleavePhone"
                    class="form-control"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="9">
              <b-form-group label="E-mail" label-for="email">
                <validation-provider #default="{ errors }" name="E-mail" rules="required|email">
                  <b-form-input id="email" type="text" placeholder="E-mail" v-model="dados.email" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="3">
              <b-form-group label="Portador de Moléstia Grave?" label-for="portadorMolestiaGrave" v-slot="{ ariaDescribedby }">
                <validation-provider #default="{ errors }" name="Portador de Moléstia Grave?" rules="required">
                  <b-form-radio-group
                    id="portadorMolestiaGrave"
                    v-model="dados.portadorMolestiaGrave"
                    :options="optionsSimNao"
                    :aria-describedby="ariaDescribedby"
                    button-variant="outline-primary"
                    class="w-100"
                    name="radio-btn-outline"
                    buttons
                  ></b-form-radio-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group label="Portador de Doença Incapacitante?" label-for="email" v-slot="{ ariaDescribedby }">
                <validation-provider #default="{ errors }" name="Portador de Doença Incapacitante?" rules="required">
                  <b-form-radio-group
                    id="portadorDoencaIncapacitante"
                    v-model="dados.portadorDoencaIncapacitante"
                    :options="optionsSimNao"
                    :aria-describedby="ariaDescribedby"
                    button-variant="outline-primary"
                    class="w-100"
                    name="radio-btn-outline"
                    buttons
                  ></b-form-radio-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="3" v-if="dados.id">
              <b-form-group label="Falecido?" label-for="falecido" v-slot="{ ariaDescribedby }">
                <validation-provider #default="{ errors }" name="Falecido?" rules="required">
                  <b-form-radio-group
                    id="falecido"
                    v-model="dados.falecido"
                    :options="optionsSimNao"
                    :aria-describedby="ariaDescribedby"
                    button-variant="outline-primary"
                    class="w-100"
                    name="radio-btn-outline"
                    buttons
                  ></b-form-radio-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="3" v-if="dados.id && dados.falecido">
              <b-form-group label="Data do Óbito" label-for="dataObito">
                <validation-provider
                  #default="{ errors }"
                  name="Data do Óbito"
                  :rules="{
                    required: true,
                    dataPassada: {
                      value: dados.dataObito,
                      dataParametro: dados.dataNascimento,
                      stringParametro: 'data de nascimento',
                    },
                    dataFutura: true,
                  }"
                >
                  <b-input-group>
                    <b-input-group-prepend>
                      <b-form-datepicker
                        v-model="dataObitoPicker"
                        button-variant="primary"
                        class="datePickerWithInput"
                        right
                        button-only
                        :hide-header="true"
                        :initial-date="new Date()"
                        :state="errors[0] ? false : null"
                        label-help=""
                        label-calendar="Calendário"
                        label-close-button="Fechar"
                        label-current-month="Mês atual"
                        label-next-decade="Próxima década"
                        label-next-month="Próximo mês"
                        label-next-year="Próximo ano"
                        label-prev-decade="Década anterior"
                        label-prev-month="Mês anterior"
                        label-prev-year="Ano anterior"
                        label-select-month="Selecione o mês"
                        label-select-year="Selecione o ano"
                        label-selected="Selecionado"
                        label-no-date-selected="Nenhuma data selecionada"
                        label-reset-button="Resetar"
                        label-today-button="Hoje"
                        label-today="Hoje"
                        locale="pt"
                        :date-format-options="{ year: 'numeric', month: '2-digit', day: 'numeric' }"
                        aria-controls="dataObito"
                      />
                    </b-input-group-prepend>

                    <cleave
                      v-model="dataObitoFormatted"
                      id="dataObito"
                      type="text"
                      placeholder="DD/MM/AAAA"
                      class="form-control cleave-height"
                      :options="options.cleaveDate"
                      :raw="false"
                    />
                  </b-input-group>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="6" v-if="dados.falecido && dados.id">
              <div class="flex w-full items-center justify-center" id="app">
                <div class="p-12 bg-gray-100">
                  <validation-provider #default="{ errors }" name="Certidão de Óbito" rules="required">
                    <b-form-group label="Certidão de Óbito" label-for="certidaoObito">
                      <b-form-file
                        id="certidaoObito"
                        :state="Boolean(dados.certidaoObito)"
                        ref="certidaoObitoFile"
                        accept=".pdf"
                        placeholder="Escolha um arquivo ou arraste até aqui ..."
                        drop-placeholder="Solte o arquivo aqui..."
                        browse-text="Escolher"
                        v-model="dados.certidaoObito"
                      />
                    </b-form-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-tab>

        <!-- Endereço -->
        <b-tab>
          <template #title>
            <feather-icon icon="MapPinIcon" />
            <span>Endereço</span>
          </template>
          <b-row>
            <b-col md="4">
              <b-form-group label="País de Residência" label-for="paisResidencia">
                <validation-provider #default="{ errors }" name="País de Residência" rules="required">
                  <b-form-select
                    name="paisResidencia"
                    id="paisResidencia"
                    v-model="dados.paisEndereco"
                    :options="nacionalidade"
                    label="nome"
                    value-field="value"
                    text-field="text"
                  ></b-form-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="dados.paisEndereco === BRAZIL_COUNTRY_CODE">
            <b-col md="2">
              <b-form-group label="CEP" label-for="cepEndereco">
                <validation-provider #default="{ errors }" name="CEP" rules="required|cep">
                  <cleave
                    id="cepEndereco"
                    type="text"
                    placeholder="CEP"
                    v-model="dados.cepEndereco"
                    maxlength="10"
                    @keyup.native="buscarCEP"
                    :raw="false"
                    :options="options.cleaveCEP"
                    class="form-control"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="5">
              <b-form-group label="Logradouro" label-for="logradouroEndereco">
                <validation-provider #default="{ errors }" name="Logradouro" rules="required">
                  <b-form-input
                    v-bloqueioCaractereMask="{ tamanho: 50, regex: /[^\w ]/ }"
                    id="logradouroEndereco"
                    type="text"
                    placeholder="Logradouro"
                    v-model="dados.logradouroEndereco"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="Número" label-for="numeroEndereco">
                <validation-provider #default="{ errors }" name="Número" rules="required">
                  <b-form-input id="numeroEndereco" type="text" placeholder="Número" v-model="dados.numeroEndereco" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Complemento" label-for="complementoEndereco">
                <b-form-input
                  id="complementoEndereco"
                  type="text"
                  placeholder="Complemento"
                  v-model="dados.complementoEndereco"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="dados.paisEndereco === BRAZIL_COUNTRY_CODE">
            <b-col md="4">
              <b-form-group label="Bairro" label-for="bairroEndereco">
                <validation-provider #default="{ errors }" name="Bairro" rules="required|min:2">
                  <b-form-input id="bairroEndereco" type="text" placeholder="Bairro" v-model="dados.bairroEndereco" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="UF" label-for="ufEndereco">
                <validation-provider #default="{ errors }" name="UF" rules="required">
                  <b-form-select
                    name="ufEndereco"
                    v-model="dados.ufEndereco"
                    :options="ufOpcoes"
                    v-on:change="carregarCidadeEndereco"
                  ></b-form-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="5">
              <b-form-group label="Cidade" label-for="cidadeEndereco">
                <validation-provider #default="{ errors }" name="Cidade" rules="required">
                  <v-select-pt
                    name="cidadeEndereco"
                    v-model="dados.cidadeEndereco"
                    :options="cidadeEndereco"
                    :reduce="(option) => option.nome"
                    label="nome"
                  ></v-select-pt>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
                <small class="text-primary d-block"
                  >Digite o CEP, ou selecione a UF, para que as cidades sejam carregadas</small
                >
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="dados.paisEndereco !== BRAZIL_COUNTRY_CODE">
            <b-col md="4">
              <b-form-group label="Cidade de residência" label-for="cidadePaisEndereco">
                <validation-provider #default="{ errors }" name="Cidade de residência" rules="required">
                  <b-form-input
                    id="cidadePaisEndereco"
                    type="text"
                    placeholder="Cidade de residência"
                    v-model="dados.cidadePaisEndereco"
                    v-bloqueioCaractereMask="{ tamanho: 70 }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Estado/Província" label-for="estadoProvinciaPaisEndereco">
                <b-form-input
                  id="estadoProvinciaPaisEndereco"
                  type="text"
                  placeholder="Estado/Província"
                  v-model="dados.estadoProvinciaPaisEndereco"
                  v-bloqueioCaractereMask="{ tamanho: 70 }"
                />
              </b-form-group>
            </b-col>
            <b-col md="5">
              <b-form-group label="Endereço" label-for="enderecoPaisEndereco">
                <validation-provider #default="{ errors }" name="Endereço" rules="required">
                  <b-form-input
                    id="enderecoPaisEndereco"
                    type="text"
                    placeholder="Endereço"
                    v-model="dados.enderecoPaisEndereco"
                    v-bloqueioCaractereMask="{ tamanho: 70 }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Código Postal" label-for="codigoPostalPaisEndereco">
                <b-form-input
                  id="codigoPostalPaisEndereco"
                  type="text"
                  placeholder="Código Postal"
                  v-model="dados.codigoPostalPaisEndereco"
                  v-bloqueioCaractereMask="{ tamanho: 70 }"
                />
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Número" label-for="numeroPaisEndereco">
                <b-form-input
                  id="numeroPaisEndereco"
                  type="text"
                  placeholder="Número"
                  v-model="dados.numeroPaisEndereco"
                  v-bloqueioCaractereMask="{ tamanho: 70 }"
                />
              </b-form-group>
            </b-col>
            <b-col md="5">
              <b-form-group label="Complemento" label-for="complementoPaisEndereco">
                <b-form-input
                  id="complementoPaisEndereco"
                  type="text"
                  placeholder="Complemento"
                  v-model="dados.complementoPaisEndereco"
                  v-bloqueioCaractereMask="{ tamanho: 70 }"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-tab>

        <!-- Dados Bancários -->
        <b-tab>
          <template #title>
            <feather-icon icon="DollarSignIcon" />
            <span>Dados Bancários</span>
          </template>
          <b-row>
            <b-col md="6">
              <b-form-group label="Banco" label-for="dadoBancarioBanco">
                <validation-provider #default="{ errors }" name="Banco" rules="required">
                  <v-select-pt
                    name="dadoBancarioBanco"
                    v-model="dados.bancoId"
                    :options="banco"
                    :reduce="(option) => option.id"
                    label="nome"
                  ></v-select-pt>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Agência" label-for="dadoBancarioAgencia">
                <validation-provider #default="{ errors }" name="Agência" rules="required">
                  <cleave
                    id="dadoBancarioAgencia"
                    type="text"
                    placeholder="Agência"
                    v-model="dados.dadoBancarioAgencia"
                    maxlength="4"
                    :raw="false"
                    :options="options.cleaveAgencia"
                    class="form-control"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="DV" label-for="dadoBancarioAgenciaDV">
                <validation-provider #default="{ errors }" name="Agencia DV" rules="required">
                  <b-form-input
                    id="dadoBancarioAgenciaDV"
                    type="text"
                    placeholder="DV"
                    v-model="dados.dadoBancarioAgenciaDV"
                    v-bloqueioCaractereMask="{ tamanho: 2, regex: /[^0-9Xx]/ }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="3">
              <b-form-group label="Conta Corrente" label-for="dadoBancarioConta">
                <validation-provider #default="{ errors }" name="Conta Corrente" rules="required">
                  <b-form-input
                    id="dadoBancarioConta"
                    type="text"
                    placeholder="Conta Corrente"
                    v-model="dados.dadoBancarioConta"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Conta Corrente DV" label-for="dadoBancarioContaDV">
                <validation-provider #default="{ errors }" name="Conta Corrente DV" rules="required">
                  <b-form-input
                    id="dadoBancarioContaDV"
                    type="text"
                    placeholder="Conta Corrente DV"
                    v-model="dados.dadoBancarioContaDV"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="OP" label-for="dadoBancarioContaOP">
                <validation-provider #default="{ errors }" name="OP" rules="required">
                  <cleave
                    id="dadoBancarioContaOP"
                    type="text"
                    placeholder="OP"
                    v-model="dados.dadoBancarioContaOP"
                    maxlength="3"
                    :raw="false"
                    :options="options.cleaveOp"
                    class="form-control"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Tipo de Conta" label-for="dadoBancarioFormaDePagamento">
                <validation-provider #default="{ errors }" name="Forma de Pagamento" rules="required">
                  <v-select-pt
                    name="dadoBancarioFormaDePagamento"
                    v-model="dados.dadoBancarioFormaDePagamento"
                    :options="formasDePagamento"
                    :reduce="(option) => option.value"
                    label="text"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="3">
              <b-form-group label="Tipo de Chave Pix" label-for="tipoChavePix">
                <validation-provider #default="{ errors }" name="Tipo de Chave Pix">
                  <v-select-pt
                    placeholder="Selecione"
                    name="tipoChavePix"
                    v-model="dados.tipoChavePix"
                    :options="tipoChavePixOpcoes"
                    :reduce="(option) => option.value"
                    label="text"
                    on-clear="dados.chavePix = ''"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group v-if="dados.tipoChavePix" label="Chave Pix" label-for="chavePix">
                <validation-provider #default="{ errors }" name="Chave Pix" rules="required">
                  <Cleave
                    v-if="dados.tipoChavePix === 'cpf'"
                    id="chavePix"
                    type="text"
                    placeholder="000.000.000-00"
                    v-model="dados.chavePix"
                    :raw="false"
                    :options="options.cleaveCPF"
                    class="form-control"
                    @input="removePontos"
                    @blur="validaCamposDocumentos((campo = dados.chavePix), 'chavePixCPF')"
                  />

                  <Cleave
                    v-else-if="dados.tipoChavePix === 'cnpj'"
                    id="chavePix"
                    type="text"
                    placeholder="00.000.000/0001-00"
                    v-model="dados.chavePix"
                    :raw="false"
                    :options="options.cleaveCNPJ"
                    class="form-control"
                    @input="removePontos"
                    @blur="validaCamposDocumentos((campo = dados.chavePix), 'chavePixCNPJ')"
                  />

                  <cleave
                    v-else-if="dados.tipoChavePix == 'email'"
                    id="chavePix"
                    type="email"
                    placeholder="exemplo@exemplo.com"
                    v-model="dados.chavePix"
                    :options="options.cleaveEmail"
                    :raw="false"
                    class="form-control"
                    @blur="validaCamposDocumentos((campo = dados.chavePix), 'chavePixEMAIL')"
                  />

                  <cleave
                    v-else-if="dados.tipoChavePix == 'telefone'"
                    id="chavePix"
                    type="tel"
                    placeholder="(99) 9 9999-9999"
                    v-model="dados.chavePix"
                    :raw="false"
                    :options="options.cleavePhone"
                    class="form-control"
                    @input="removePontos"
                    @blur="validaCamposDocumentos((campo = dados.chavePix), 'chavePixTELEFONE')"
                  />

                  <b-form-input v-else id="chavePix" type="text" placeholder="Chave aleatória" v-model="dados.chavePix" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-tab>
      </validation-observer>

      <!-- Segurado/Beneficiário -->
      <b-tab :disabled="!isEditing">
        <template #title>
          <feather-icon icon="UsersIcon" />
          <span>Segurado/Beneficiário</span>
        </template>
        <ValidationObserver ref="formularioSegurado">
          <b-row>
            <b-col md="4">
              <b-form-group label="Matrícula" label-for="matricula">
                <validation-provider #default="{ errors }" name="Matrícula" rules="required">
                  <b-form-input id="matricula" type="text" placeholder="Matrícula" v-model="segurado.matricula" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group label="Órgão" label-for="orgao">
                <validation-provider #default="{ errors }" name="Órgão" rules="required">
                  <v-select-pt
                    name="orgao"
                    v-model="segurado.orgao"
                    :options="orgao"
                    :reduce="(option) => option.id"
                    label="nome"
                    @option:selected="limpaGrupoFolha"
                    @search:blur="carregarGrupoFolha"
                  ></v-select-pt>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group label="Grupo Folha" label-for="grupoFolha">
                <validation-provider #default="{ errors }" name="Grupo Folha" rules="required">
                  <v-select-pt
                    name="grupoFolha"
                    v-model="segurado.grupoFolhaId"
                    :disabled="!segurado.orgao"
                    :options="gruposFolha"
                    :reduce="(option) => option.id"
                    label="nomeDoGrupo"
                  ></v-select-pt>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="4">
              <b-form-group label="Remuneração (R$)" label-for="remuneracao">
                <validation-provider #default="{ errors }" name="Remuneração" rules="required">
                  <b-input-group>
                    <b-input-group-prepend>
                      <span class="input-group-text">R$</span>
                    </b-input-group-prepend>
                    <b-form-input
                      id="remuneracao"
                      v-number="number"
                      type="text"
                      v-model="segurado.remuneracao"
                      placeholder="Remuneração (R$)"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Margem Utilizada (R$)" label-for="margemUtilizada">
                <validation-provider #default="{ errors }" name="Margem Utilizada" rules="required">
                  <b-input-group>
                    <b-input-group-prepend>
                      <span class="input-group-text">R$</span>
                    </b-input-group-prepend>
                    <b-form-input
                      id="margemUtilizada"
                      v-number="number"
                      type="text"
                      v-model="segurado.margemUtilizada"
                      placeholder="Margem Utilizada (R$)"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Margem Disponível (R$)" label-for="margemDisponivel">
                <validation-provider #default="{ errors }" name="Margem Disponível" rules="required">
                  <b-input-group>
                    <b-input-group-prepend>
                      <span class="input-group-text">R$</span>
                    </b-input-group-prepend>
                    <b-form-input
                      id="margemDisponivel"
                      v-number="number"
                      type="text"
                      v-model="segurado.margemDisponivel"
                      placeholder="Margem Disponível (R$)"
                    />
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="4">
              <b-form-group label="Categoria" label-for="categoria">
                <validation-provider #default="{ errors }" name="Categoria" rules="required">
                  <b-form-select
                    @change="limparCargo"
                    name="categoria"
                    v-model="segurado.categoria"
                    :options="categoriaOpcoes"
                  ></b-form-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="auto" v-if="segurado.categoria == 'Pensionista'">
              <b-form-group label="É Vitalício?" label-for="ehVitalicio" id="divEhVitalicio">
                <b-form-checkbox
                  switch
                  id="ehVitalicio"
                  v-model="segurado.ehVitalicio"
                  name="ehVitalicio"
                  :value="true"
                  :unchecked-value="false"
                ></b-form-checkbox>
              </b-form-group>
            </b-col>

            <b-col md="4" v-if="segurado.categoria == 'Efetivo' || segurado.categoria == 'Estável'">
              <b-form-group label="Data de Admissão" label-for="dataAdmissao">
                <validation-provider #default="{ errors }" name="Data de Admissão" rules="required">
                  <cleave
                    id="dataAdmissao"
                    class="form-control"
                    type="text"
                    placeholder="DD/MM/AAAA"
                    @blur="validaDataSegurado('dataAdmissao')"
                    v-model="segurado.dataAdmissao"
                    :options="options.cleaveDate"
                    :raw="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4" v-if="(segurado.categoria == 'Efetivo' || segurado.categoria == 'Estável') && segurado.id">
              <b-form-group label="Exonerado?" label-for="Exonerado" v-slot="{ ariaDescribedby }">
                <validation-provider #default="{ errors }" name="Exonerado?" rules="required">
                  <b-form-radio-group
                    id="exonerado"
                    v-model="segurado.exonerado"
                    :options="optionsSimNao"
                    :aria-describedby="ariaDescribedby"
                    button-variant="outline-primary"
                    class="w-100"
                    name="radio-btn-outline"
                    buttons
                  ></b-form-radio-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4" v-if="segurado.exonerado">
              <b-form-group label="Data de Exoneração" label-for="dataExoneracao">
                <validation-provider
                  #default="{ errors }"
                  name="Data de Exoneração"
                  :rules="{
                    required: true,
                    dataPassada: {
                      value: segurado.dataExoneracao,
                      dataParametro: segurado.dataNascimento,
                      stringParametro: 'data de exoneração',
                    },
                  }"
                >
                  <b-input-group>
                    <cleave
                      ref="dataExoneracao"
                      id="dataExoneracao"
                      type="text"
                      v-model="segurado.dataExoneracao"
                      placeholder="DD/MM/AAAA"
                      class="form-control cleave-height"
                      :options="options.cleaveDate"
                      :raw="false"
                    />
                    <b-input-group-append>
                      <b-form-datepicker
                        button-variant="primary"
                        class="datePickerWithInput"
                        size="sm"
                        rounded="sm"
                        right
                        button-only
                        v-model="datePicker.dataExoneracao"
                        :hide-header="true"
                        :initial-date="new Date()"
                        :state="errors[0] ? false : null"
                        label-help=""
                        label-calendar="Calendário"
                        label-close-button="Fechar"
                        label-current-month="Mês atual"
                        label-next-decade="Próxima década"
                        label-next-month="Próximo mês"
                        label-next-year="Próximo ano"
                        label-prev-decade="Década anterior"
                        label-prev-month="Mês anterior"
                        label-prev-year="Ano anterior"
                        label-select-month="Selecione o mês"
                        label-select-year="Selecione o ano"
                        label-selected="Selecionado"
                        label-no-date-selected="Nenhuma data selecionada"
                        label-reset-button="Resetar"
                        label-today-button="Hoje"
                        label-today="Hoje"
                        locale="en"
                        :date-format-options="{ year: 'numeric', month: '2-digit', day: 'numeric' }"
                        aria-controls="dataExoneracao"
                      />
                    </b-input-group-append>
                  </b-input-group>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="6" v-if="!portariaExoneracaoAnexada.id && segurado.exonerado && segurado.id">
              <div class="flex w-full items-center justify-center" id="app">
                <div class="p-12 bg-gray-100">
                  <validation-provider #default="{ errors }" name="Portaria de Exoneração" rules="required">
                    <b-form-group label="Portaria de Exoneração" label-for="portariaExoneracao">
                      <b-form-file
                        id="portariaExoneracao"
                        :state="Boolean(segurado.portariaExoneracao)"
                        ref="portariaExoneracaoFile"
                        accept=".pdf"
                        placeholder="Escolha um arquivo ou arraste até aqui ..."
                        drop-placeholder="Solte o arquivo aqui..."
                        browse-text="Escolher"
                        v-model="segurado.portariaExoneracao"
                      >
                      </b-form-file>
                    </b-form-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </div>
              </div>
            </b-col>

            <b-col
              class="mr-0 pr-0"
              style="width: min-content"
              sm="12"
              md="4"
              v-if="portariaExoneracaoAnexada.id && segurado.exonerado && segurado.id"
            >
              <b-button
                :disabled="isBusy"
                class="w-100 mt-2"
                id="portariaExoneracao"
                variant="primary"
                @click="downloadPortariaExoneracaoAnexada"
              >
                <b-spinner small v-if="isBusy" />
                <span v-if="!isBusy" class="mr-05">Download Portaria de Exoneração </span>
                <feather-icon v-if="!isBusy" icon="DownloadIcon" size="14" />
              </b-button>
            </b-col>

            <b-col v-if="portariaExoneracaoAnexada.id && segurado.exonerado && segurado.id" md="auto">
              <b-button class="mt-2" variant="danger" @click="excluirPortariaExoneracaoAnexada">Excluir</b-button>
            </b-col>

            <b-col md="3" v-if="segurado.categoria == 'Aposentado' || segurado.categoria == 'Pensionista'">
              <b-form-group label="Data Início do Benefício" label-for="dataInicioBeneficio">
                <validation-provider #default="{ errors }" name="Data Início do Benefício" rules="required">
                  <cleave
                    id="dataInicioBeneficio"
                    class="form-control"
                    type="text"
                    placeholder="DD/MM/AAAA"
                    v-model="segurado.dataInicioBeneficio"
                    :options="options.cleaveDate"
                    :raw="false"
                  />
                  <!-- @blur="validaDataSegurado('dataInicioBeneficio')" -->
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="3" v-if="segurado.categoria == 'Aposentado'">
              <b-form-group label="Tipo de benefício" label-for="tipoBeneficio">
                <validation-provider #default="{ errors }" name="tipoBeneficio" rules="required">
                  <b-form-select
                    id="tipoBeneficio"
                    name="tipoBeneficio"
                    v-model="segurado.tipoBeneficio"
                    :options="beneficioOpcoes"
                  ></b-form-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="3" v-if="segurado.categoria == 'Pensionista' && segurado.ehVitalicio == false">
              <b-form-group label="Data Fim do Benefício" label-for="dataFimBeneficio">
                <validation-provider #default="{ errors }" name="Data Fim do Benefício" rules="required">
                  <cleave
                    id="dataFimBeneficio"
                    class="form-control"
                    type="text"
                    placeholder="DD/MM/AAAA"
                    @blur="validaDataSegurado('dataFimBeneficio')"
                    v-model="segurado.dataFimBeneficio"
                    :options="options.cleaveDate"
                    :raw="false"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4" v-if="this.dados.id">
              <b-form-group label="Bloqueado judicialmente?" label-for="bloqueioJudicial" v-slot="{ ariaDescribedby }">
                <validation-provider #default="{ errors }" name="Bloqueado judicialmente" rules="required">
                  <b-form-radio-group
                    id="bloqueioJudicial"
                    v-model="segurado.bloqueioJudicial"
                    :options="optionsSimNao"
                    :aria-describedby="ariaDescribedby"
                    button-variant="outline-primary"
                    class="w-100"
                    name="radio-btn-outline"
                    buttons
                  ></b-form-radio-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4" v-if="this.dados.id && segurado.bloqueioJudicial">
              <b-form-group label="Data da suspensão judicial" label-for="dataBloqueioJudicial">
                <validation-provider #default="{ errors }" name="Data da suspensão judicial" rules="required">
                  <cleave
                    id="dataBloqueioJudicial"
                    class="form-control"
                    type="text"
                    placeholder="DD/MM/AAAA"
                    @blur="validaDataSegurado('dataBloqueioJudicial')"
                    v-model="segurado.dataBloqueioJudicial"
                    :options="options.cleaveDate"
                    :raw="false"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Cargo" label-for="cargo">
                <validation-provider #default="{ errors }" name="Cargo" rules="required">
                  <b-form-input
                    :disabled="segurado.categoria == 'Pensionista' || segurado.categoria == 'Aposentado'"
                    id="cargo"
                    type="text"
                    placeholder="Cargo"
                    v-model="formatarCargoSegurado"
                    v-on:keypress="isLetter($event)"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </ValidationObserver>
        <!-- Actions -->
        <b-row>
          <b-col md="4">
            <b-form-group label=" " label-for="btnEraseFormSegurado">
              <b-button
                name="btnCreateEditSegurado"
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="primary"
                @click="createEditSegurado()"
              >
                {{ isCreateSegurado ? 'Salvar' : 'Cadastrar' }}
              </b-button>
              <b-button
                name="btnEraseFormSegurado"
                class="ml-1"
                v-ripple.400="'rgba(40, 74, 199, 0.4)'"
                variant="secondary"
                @click="eraseFormSegurado()"
              >
                Limpar
              </b-button>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-table striped :items="tomadorSegurados" :fields="fieldsTableSegurados">
            <template #cell(opcoes)="row">
              <b-dropdown variant="link" no-caret toggle-class="p-0" left :popper-opts="{ positionFixed: true }">
                <template #button-content>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="btn-icon"
                    title="Este botão contém as opções disponíveis para este registro"
                  >
                    <feather-icon icon="SettingsIcon" />
                  </b-button>
                </template>
                <b-dropdown-item @click="editarSegurado(row.item)">
                  <feather-icon icon="EditIcon" size="16" />
                  <span class="align-middle ml-50">Editar</span>
                </b-dropdown-item>
                <b-dropdown-item @click="excluirSegurado(row.item.id)">
                  <feather-icon icon="XIcon" size="16" />
                  <span class="align-middle ml-50">Excluir</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
            <template #cell(margemDisponivel)="row">
              {{ formatarValor(Number(row.item.margemDisponivel)) }}
            </template>
            <template #cell(remuneracao)="row">
              {{ formatarValor(Number(row.item.remuneracao)) }}
            </template>
          </b-table>
        </b-row>
      </b-tab>

      <!-- Arquivos do Tomador -->
      <b-tab :disabled="!isEditing">
        <template #title>
          <feather-icon icon="FileTextIcon" />
          <span>Arquivos</span>
        </template>
        <ValidationObserver ref="arquivosObserver">
          <b-row>
            <b-col>
              <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'" variant="primary" @click="anexarArquivo">
                Anexar Arquivo
              </b-button>
            </b-col>
          </b-row>
          <div class="table-overflow">
            <b-row class="mt-1">
              <b-table v-if="itemsArquivos.length" :items="itemsArquivos" :fields="fieldsTableArquivos" :busy="isBusyArquivos">
                <template v-slot:table-busy>
                  <div class="text-center my-2">
                    <b-spinner class="align-middle mr-1"></b-spinner>
                    <strong> Carregando...</strong>
                  </div>
                </template>

                <template #cell(opcoes)="row">
                  <b-dropdown variant="link" no-caret toggle-class="p-0" left :popper-opts="{ positionFixed: true }">
                    <template #button-content>
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="btn-icon"
                        title="Este botão contém as opções disponíveis para este registro"
                      >
                        <feather-icon icon="SettingsIcon" />
                      </b-button>
                    </template>
                    <b-dropdown-item @click="downloadArquivo(row.item)">
                      <feather-icon icon="DownloadIcon" size="16" />
                      <span class="align-middle ml-50">Download</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="excluirArquivo(row.item.id)">
                      <feather-icon icon="XIcon" size="16" />
                      <span class="align-middle ml-50">Excluir</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </b-table>
            </b-row>
          </div>
        </ValidationObserver>
      </b-tab>
    </b-tabs>

    <!-- Anexar Documentos -->
    <b-modal
      id="modal-enviar-documento"
      ref="modal-enviar-documento"
      size="lg"
      no-close-on-backdrop
      centered
      title="Anexar Documento(s)"
      hide-footer
    >
      <UploadArquivoTomador
        @fecharModalUploadArquivo="fecharModalUploadArquivo"
        @uploadEvent="anexarArquivo"
        :parametro="parametro"
      />
    </b-modal>

    <b-modal id="modal-foto" ref="modal-foto" centered size="lg" title="Captura de Foto" hide-footer no-close-on-backdrop>
      <div class="camera-canvas">
        <video v-if="isCameraOpen" ref="camera" :width="canvasWidth" :height="canvasHeight" autoplay></video>
        <canvas v-show="!isCameraOpen" ref="canvas" :width="canvasWidth" :height="canvasHeight"></canvas>
      </div>

      <template>
        <b-button size="sm" variant="primary" v-show="isCameraOpen" @click="capture">Tirar Foto</b-button>
        <b-button size="sm" variant="primary" class="ml-1" v-show="isCameraOpen" @click="openFileDialog">Anexar Foto</b-button>
        <input type="file" ref="fileInput" @change="handleFileUpload" accept="image/*" style="display: none" />
        <b-button size="sm" variant="success" v-show="!isCameraOpen" @click="confirmarFoto()" :disabled="loading"
          >Confirmar Foto</b-button
        >
        <b-button size="sm" class="ml-1" variant="danger" @click="resetCamera" v-show="!isCameraOpen">Limpar Foto</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
  import downloadFileAWS from '@/@core/utils/Download-File-AWS.ts'
  import { cep, email, rule_cep, telefone } from '@/@core/utils/validations/validations'
  import useJwt from '@/auth/jwt/useJwt'
  import {
    Ufs,
    formatarParaNumber,
    formatarTipoDate,
    formatarValor,
    isCPFValid,
    isLetter,
    isNumber,
    toNumeric,
    validaCNPJ,
    validaRG,
    validarCnpj,
    validarData,
    validarEmail,
    validarTelefone,
  } from '@/libs/utils'
  import { getDownloadArquivoAws } from '@/utils/aws/getDownloadArquivoAws'
  import { formatarData, formatarDataGlobal } from '@/utils/Functions/Formatacao/Formatacao'
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import { data, dataFutura, dataPassada, required } from '@validations'
  import 'cleave.js/dist/addons/cleave-phone.br'
  import { ValidationObserver, ValidationProvider } from 'vee-validate'
  import Cleave from 'vue-cleave-component'
  import Ripple from 'vue-ripple-directive'
  import { VueSelect } from 'vue-select'
  import UploadArquivoTomador from './components/uploadArquivoTomador.vue'

  export default {
    components: {
      BCardCode,
      ValidationProvider,
      ValidationObserver,
      formatarData,
      formatarDataGlobal,
      isNumber,
      toNumeric,
      validarData,
      Cleave,
      formatarValor,
      VueSelect,
      dataPassada,
      dataFutura,
      validaRG,
      validaCNPJ,
      formatarTipoDate,
      telefone,
      email,
      cep,
      rule_cep,
      UploadArquivoTomador,
      data,
    },
    directives: {
      Ripple,
    },
    props: {
      parametro: {},
      isEditing: true,
    },
    data() {
      return {
        required,
        isLetter,
        isCPFValid,
        isNumber,
        toNumeric,
        validarData,
        formatarValor,
        dataPassada,

        beneficioOpcoes: [
          { value: 'aposentadoriaPorIncapacidadePermanente', text: 'Aposentadoria por Incapacidade Permanente' },
          { value: 'aposentadoriaPorTempoDeContribuição', text: 'Aposentadoria por Tempo de Contribuição' },
          { value: 'aposentadoriaCompulsoria', text: 'Aposentadoria Compulsória' },
          { value: 'aposentadoriaPorIdade', text: 'Aposentadoria por Idade' },
          { value: 'aposentadoriaPorTempoDeServico', text: 'Aposentadoria por Tempo de Serviço' },
        ],
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        isBusy: false,
        pesquisar: {
          tipoOpcoes: 1,
          descricao: '',
          perPage: 25,
          currentPage: 1,
        },
        loading: false,
        isCreateSegurado: false,
        number: 0.0,
        canvasHeight: 255,
        canvasWidth: 340,
        isCameraOpen: false,
        tipoOpcoes: [
          { value: 1, text: 'Nome' },
          { value: 2, text: 'CPF' },
          { value: 3, text: 'Matrícula' },
        ],
        formasDePagamento: [
          { value: 'creditoEmContaCorrente', text: 'Crédito em Conta Corrente' },
          { value: 'creditoEmContaPoupanca', text: 'Crédito em Conta Poupança' },
        ],
        tipoChavePixOpcoes: [
          { value: 'cpf', text: 'CPF' },
          { value: 'cnpj', text: 'CNPJ' },
          { value: 'email', text: 'E-mail' },
          { value: 'telefone', text: 'Telefone' },
          { value: 'aleatoria', text: 'Aleatória' },
        ],
        sexoOpcoes: [
          { value: '', text: 'Selecione' },
          { value: 'M', text: 'Masculino' },
          { value: 'F', text: 'Feminino' },
        ],
        generoOpcoes: [
          { value: '', text: 'Selecione' },
          { value: 'T', text: 'Transgênero' },
          { value: 'C', text: 'Cisgênero' },
          { value: 'N', text: 'Não-Binário' },
        ],
        estadoCivilOpcoes: [
          { value: '', text: 'Selecione' },
          { value: 'Solteiro(a)', text: 'Solteiro(a)' },
          { value: 'Casado(a)', text: 'Casado(a)' },
          { value: 'Separado(a)', text: 'Separado(a)' },
          { value: 'Divorciado(a)', text: 'Divorciado(a)' },
          { value: 'Viúvo(a)', text: 'Viúvo(a)' },
        ],
        excluirSegurados: [],
        tomadorSegurados: [],
        nacionalidade: [],
        banco: [],
        cidadeNaturalidade: [],
        cidadeEndereco: [],
        ufOpcoes: Ufs,
        ufOpcoes2: Ufs,
        orgao: [],
        categoriaOpcoes: [
          { value: '', text: 'Selecione' },
          { value: 'Efetivo', text: 'Efetivo' },
          { value: 'Estável', text: 'Estável' },
          { value: 'Pensionista', text: 'Pensionista' },
          { value: 'Aposentado', text: 'Aposentado' },
        ],
        options: {
          cleaveCPF: {
            delimiters: ['.', '.', '-'],
            blocks: [3, 3, 3, 2],
            numericOnly: true,
          },
          cleaveEmail: {
            blocks: [100],
          },
          cleaveCNPJ: {
            delimiters: ['.', '.', '/', '-'],
            numericOnly: true,
            blocks: [2, 3, 3, 4, 2],
          },
          cleaveRG: {
            blocks: [25],
          },
          cleaveDate: {
            date: true,
            delimiter: '/',
            datePattern: ['d', 'm', 'Y'],
          },
          cleavePhone: {
            numericOnly: true,
            delimiters: ['(', ')', ' ', '-'],
            blocks: [0, 2, 0, 5, 4],
          },
          cleaveCEP: {
            delimiters: ['-'],
            blocks: [5, 3],
          },
          cleaveAgencia: {
            numericOnly: true,
            blocks: [4],
          },
          cleaveOp: {
            blocks: [3],
          },
        },
        optionsSimNao: [
          { text: 'Sim', value: true },
          { text: 'Não', value: false },
        ],
        pageOptions: [25, 50, 100],
        totalRows: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        cleaveCurrency: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          delimiter: '.',
          numeralDecimalMark: ',',
          numeralDecimalScale: 2,
        },
        fieldsTableSegurados: [
          { key: 'opcoes', label: 'OPÇÕES' },
          { key: 'matricula', label: 'Matrícula' },
          {
            key: 'situacaoPrevidenciaria',
            label: 'Categoria',
          },
          { key: 'margemDisponivel', label: 'Margem Disponível' },
          { key: 'remuneracao', label: 'Remuneração' },
          { key: 'orgao.nome', label: 'Órgão' },
        ],
        fields: [
          {
            key: 'opcoes',
            label: 'Opções',
          },
          {
            key: 'avatar',
            label: '',
          },
          { key: 'nomeCompleto', label: 'Nome', sortable: true },
          { key: 'cpf', label: 'CPF', sortable: true },
          {
            key: 'dataNascimento',
            label: 'Data Nascimento',
            sortable: true,
            formatter: (value, key, item) => {
              return formatarData(value)
            },
          },
          'sexo',
          { key: 'matricula', label: 'Matrícula', sortable: true },
          { key: 'orgao.nome', label: 'Órgão', sortable: true },
          'categoria',
        ],
        items: [],
        acesso: {},
        segurado: {
          categoria: '',
          ehVitalicio: false,
          remuneracao: null,
          margemDisponivel: null,
          margemUtilizada: null,
          dataAdmissao: null,
          dataInicioBeneficio: null,
          dataFimBeneficio: null,
          orgao: null,
          ordaoId: null,
          grupoFolhaId: null,
          tipoBeneficio: null,
          exonerado: false,
          dataExoneracao: null,
          bloqueioJudicial: false,
          dataBloqueioJudicial: null,
          cargo: null,
        },
        listaSegurados: [],
        dados: {
          foto: null,
          nacionalidade: '',
          sexo: '',
          genero: '',
          portadorMolestiaGrave: false,
          portadorDoencaIncapacitante: false,
          falecido: false,
          exonerado: false,
          dataExoneracao: '',
          tipoChavePix: '',
          chavePix: '',
          categoria: '',
          naturalidade: '',
          cidadeEndereco: {
            nome: null,
          },
          rgUF: '',
          ufNaturalidade: '',
          foto: null,
          estadoCivil: '',
          dadoBancarioAgencia: '',
          dadoBancarioAgenciaDV: '',
          paisEndereco: 76,
        },
        certidaoObitoAnexada: null,
        portariaExoneracaoAnexada: {
          id: null,
        },
        tomadorWithOrg: {},
        categoria: [
          {
            Ativo: 'primary',
            Aposentado: 'light-success',
            Pensionista: 'light-danger',
          },
        ],
        gruposFolha: [],
        datePicker: {
          dataObito: '',
        },

        // Arquivos
        isBusyArquivos: false,
        itemsArquivos: [],
        fieldsTableArquivos: [
          { key: 'opcoes', label: 'OPÇÕES' },
          { key: 'nome', label: 'Nome' },
          { key: 'nomeArquivo', label: 'Arquivo' },
          { key: 'usuario', label: 'Usuário' },
          { key: 'createdAt', label: 'Data de Criação', formatter: (value) => (value ? formatarData(value) : '') },
        ],
        BRAZIL_COUNTRY_CODE: 76,
      }
    },
    computed: {
      sortOptions() {
        return this.fields.filter((f) => f.sortable).map((f) => ({ text: f.label, value: f.key }))
      },
      formatarCargoSegurado: {
        get() {
          return this.segurado.cargo ? this.formatarCategoriaSegurado(this.segurado.cargo) : ''
        },
        set(value) {
          this.segurado.cargo = value
        },
      },
      // Computada para o DatePicker:
      // Por padrão, b-form-datepicker sem "value-as-date" retorna/espera "YYYY-MM-DD".
      // Então podemos usar diretamente a prop dados.dataObito, mas vamos criar
      // uma computed para manter o código mais claro.
      dataObitoPicker: {
        get() {
          // Já está no formato YYYY-MM-DD
          return this.dados.dataObito
        },
        set(novoValor) {
          // O datepicker retorna algo como "2025-01-31".
          // Gravamos esse valor direto no dados.dataObito.
          this.dados.dataObito = novoValor
        },
      },
      // Computada para o Cleave:
      dataObitoFormatted: {
        get() {
          // Precisamos converter "YYYY-MM-DD" => "DD/MM/YYYY"
          return this.formatarParaDDMMAAAA(this.dados.dataObito)
        },
        set(valorDigitado) {
          // Quando Cleave muda, ele gera algo como "31/01/2025"
          // Convertendo "DD/MM/YYYY" => "YYYY-MM-DD" antes de salvar
          const convertido = this.formatarParaYYYYMMDD(valorDigitado)
          this.dados.dataObito = convertido
        },
      },
    },
    mounted() {
      if (this.userData.role == 'master') {
        this.acesso.CadastroTomadorEditar = true
      } else {
        this.acesso.CadastroTomadorEditar = this.userData.GrupoAcesso.CadastroTomadorEditar
      }
      this.carregarPaises()
      this.carregarOrgaos()
      this.carregarBancos()

      if (this.parametro) {
        this.editar(this.parametro.id)
        this.carregarGridArquivos(this.parametro.id)
      }
    },
    methods: {
      formatarParaDDMMAAAA(yyyyMMdd) {
        if (!yyyyMMdd) return ''
        // Supondo que venha "2025-01-31"
        const [ano, mes, dia] = yyyyMMdd.split('-')
        if (!ano || !mes || !dia) return ''
        return `${dia}/${mes}/${ano}`
      },
      formatarParaYYYYMMDD(ddMMyyyy) {
        if (!ddMMyyyy) return ''
        // Supondo que venha "31/01/2025"
        const [dia, mes, ano] = ddMMyyyy.split('/')
        if (!ano || !mes || !dia) return ''
        return `${ano}-${mes}-${dia}`
      },
      removePontos() {
        // Remove pontos da string
        this.dados.chavePix = this.dados.chavePix.replace(/[./( )-]/g, '')
      },
      buscarCEP() {
        if (rule_cep(this.dados.cepEndereco)) {
          let dadosPesquisaCep = {
            cep: this.dados.cepEndereco.replace(/\D/g, ''),
            tomadorId: this.dados.id ? this.dados.id : null,
          }

          useJwt
            .post(`cadastro/tomador/GetBuscaCepTomador`, dadosPesquisaCep)
            .then(async (response) => {
              if (response.data.status) {
                if (response.data.status == 'erro') this.$message.error(response.data.mensagem)
              } else {
                await this.carregarCidadeEndereco(response.data.uf)
                this.dados = {
                  ...this.dados,
                  complemento: response.data.complemento,
                  logradouroEndereco: response.data.logradouro,
                  bairroEndereco: response.data.bairro,
                  ufEndereco: response.data.uf,
                  cidadeEndereco: response.data.localidade,
                }
              }
            })
            .catch((error) => {
              console.error(error)
            })
        }
      },
      async carregarCidadeEndereco(item) {
        this.dados.cidadeEndereco = null
        return new Promise((resolve, reject) => {
          useJwt
            .get(`utils/cidades/${item}`)
            .then((response) => {
              this.cidadeEndereco = response.data
              return resolve(response.data)
            })
            .catch((error) => {
              console.error(error)
              return reject()
            })
        })
      },
      carregarGrupoFolha() {
        let orgaoId = this.segurado?.orgao?.id || this.segurado?.orgao
        if (orgaoId)
          useJwt
            .pesquisar('cadastro/grupoFolha/GetPesquisarPorOrgao', {
              orgaoId: orgaoId,
              institutoSelecionado: this.userData.institutoSelecionado,
            })
            .then((response) => {
              this.gruposFolha = response.data
            })
            .catch((error) => {
              console.error(error)
            })
      },
      carregarCidadeNaturalidade(item) {
        return new Promise((resolve, reject) => {
          useJwt
            .get(`utils/cidades/${item}`)
            .then((response) => {
              this.cidadeNaturalidade = response.data
              return resolve(response.data)
            })
            .catch((error) => {
              console.error(error)
              return reject()
            })
        })
      },
      limparValores() {
        ;(this.dados.naturalidade = ''), (this.dados.ufNaturalidade = '')
      },
      limparValoresEstado() {
        this.dados.naturalidade = ''
        this.carregarCidadeNaturalidade(this.dados.ufNaturalidade)
      },
      carregarPaises() {
        useJwt
          .get('utils/paises')
          .then((response) => {
            this.nacionalidade = response.data.map((cidade) => {
              return {
                text: cidade.nome,
                value: cidade.codigoFederal,
              }
            })
          })
          .catch((error) => {
            console.error(error)
          })
      },
      carregarBancos() {
        useJwt
          .get('utils/bancos')
          .then((response) => {
            this.banco = response.data
            this.banco.forEach((banco) => {
              if (banco.codigo) banco.nome = String(banco.codigo) + ' - ' + String(banco.nome)
            })
          })
          .catch((error) => {
            console.error(error)
          })
      },
      carregarOrgaos() {
        if (this.userData.institutoSelecionado) {
          useJwt
            .get(`cadastro/orgao/buscaPorInstitutoId/${this.userData.institutoSelecionado}`)
            .then((response) => {
              this.orgao = response.data
            })
            .catch((error) => {
              console.error(error)
            })
        }
      },
      async editar(item) {
        if (item)
          useJwt
            .edit(`cadastro/tomador`, item)
            .then(async (response) => {
              this.dados = {
                ...response.data,
                dataNascimento: response.data.dataNascimento ? formatarData(response.data.dataNascimento) : '',
                rgDataEmissao: response.data.rgDataEmissao ? formatarData(response.data.rgDataEmissao) : '',
                dataAdmissao: response.data.dataAdmissao ? formatarData(response.data.dataAdmissao) : '',
                dataFimBeneficio: response.data.dataFimBeneficio ? formatarData(response.data.dataFimBeneficio) : '',
                portadorMolestiaGrave: response.data.portadorMolestiaGrave ? true : false,
                portadorDoencaIncapacitante: response.data.portadorDoencaIncapacitante ? true : false,
                falecido: response.data.falecido ? true : false,
                dataObito: response.data.dataObito ? formatarData(response.data.dataObito) : '',
                tipoChavePix: response.data.tipoChavePix ? response.data.tipoChavePix : '',
                chavePix: response.data.chavePix ? response.data.chavePix : '',
              }

              if (this.dados.certidaoObito) {
                this.certidaoObitoAnexada = this.dados.certidaoObito
              }

              this.tomadorSegurados = response.data.segurado

              if (response.data.paisId) {
                this.dados.nacionalidade = this.nacionalidade.find((pais) => pais.codigoFederal == response.data.paisId)
              }
              if (this.dados.ufNaturalidade) {
                this.carregarCidadeNaturalidade(this.dados.ufNaturalidade).then((resultado) => {
                  this.dados.naturalidade = resultado.find((cidade) => cidade.nome == response.data.naturalidade)?.nome
                })
              }

              if (this.dados.ufEndereco) {
                this.carregarCidadeEndereco(this.dados.ufEndereco).then((resultado) => {
                  this.dados.cidadeEndereco = resultado.find((cidade) => cidade.nome == response.data.cidadeEndereco)?.nome
                })
              }
              if (!this.dados.paisEndereco) {
                this.dados.paisEndereco = this.BRAZIL_COUNTRY_CODE
              }
              if (this.dados.naturalidade) {
                this.dados.naturalidade = this.dados.naturalidade
              }
            })
            .catch((error) => {
              console.error(error)
            })
      },
      async salvar() {
        const invalid = !(await this.$refs['formDadosCadastraisEnderecoBancarios'].validate())
        if (invalid) {
          this.$message.error('Preencha todos os campos obrigatórios!')
          return
        }

        if (this.segurado.id) {
          this.$message.error('Existe uma edição de segurado pendente de finalização!')
          return
        }

        this.loading = true

        if (this.dados.paisEndereco !== this.BRAZIL_COUNTRY_CODE) {
          this.dados.cepEndereco = null
          this.dados.logradouroEndereco = null
          this.dados.numeroEndereco = null
          this.dados.complementoEndereco = null
          this.dados.bairroEndereco = null
          this.dados.ufEndereco = null
          this.dados.cidadeEndereco = null
        } else {
          this.dados.cidadePaisEndereco = null
          this.dados.estadoProvinciaPaisEndereco = null
          this.dados.enderecoPaisEndereco = null
          this.dados.codigoPostalPaisEndereco = null
          this.dados.numeroPaisEndereco = null
          this.dados.complementoPaisEndereco = null
        }

        let dadosInsert = {
          ...this.dados,
          dataNascimento: this.dados.dataNascimento ? formatarDataGlobal(this.dados.dataNascimento) : null,
          rgDataEmissao: this.dados.rgDataEmissao ? formatarDataGlobal(this.dados.rgDataEmissao) : null,
          dataAdmissao: this.dados.dataAdmissao ? formatarDataGlobal(this.dados.dataAdmissao) : null,
          dataFimBeneficio: this.dados.dataFimBeneficio ? formatarDataGlobal(this.dados.dataFimBeneficio) : null,
          naturalidade: this.dados.naturalidade,
          cidadeEndereco: this.dados.cidadeEndereco,
          nacionalidade: this.dados.nacionalidade,
          institutoId: this.userData.institutoSelecionado,
          tipoChavePix: this.dados.tipoChavePix,
          chavePix: this.dados.chavePix,
        }

        if (this.dados.certidaoObito) this.insereCertidaoObito()

        const { foto, ...dados } = dadosInsert

        if (!this.dados.id) {
          useJwt
            .post('cadastro/tomador', dados)
            .then((response) => {
              if (this.dados.foto) {
                useJwt
                  .post(`cadastro/tomador/cadastrarFoto`, {
                    foto: this.dados.foto.foto,
                    tomadorId: response.data.id,
                  })
                  .then((response) => {
                    this.loading = false
                    this.$emit('atualizaGrid')
                    this.mostrarMsgModal('Cadastrado com sucesso!')
                  })
                  .catch((error) => {
                    console.error(error)
                  })
              } else {
                this.loading = false
                this.mostrarMsgModal('Cadastrado com sucesso!')
              }
            })
            .catch((error) => {
              console.error(error)
              if (error.response?.status === 400) {
                this.$message.error('O CPF é obrigatório. Verifique os dados e tente novamente.')
              } else if (error.response?.status === 422) {
                this.$message.error('Este CPF já está cadastrado na unidade gestora.')
              } else {
                this.$message.error('Ocorreu um erro inesperado ao cadastrar o tomador')
              }
            })
        } else {
          useJwt
            .update(`cadastro/tomador`, this.dados.id, dados)
            .then((response) => {
              this.$emit('atualizaGrid')
              this.loading = false
              this.mostrarMsgModal('Registro alterado com sucesso!')
            })
            .catch((error) => {
              if (error.response?.status === 422) {
                this.$message.error('Este CPF já está cadastrado na unidade gestora')
                return
              }
              this.$message.error('Houve um erro ao alterar o tomador')
            })
        }
      },
      mostrarMsgModal(titulo) {
        this.$swal({
          title: 'Atenção!',
          text: titulo,
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.$emit('tomadorLoadingEvent', this.loading)
          }
        })
      },
      capturarFoto() {
        this.toggleCamera()
        this.$refs['modal-foto'].show()
      },
      toggleCamera() {
        this.isCameraOpen = true
        this.startCameraStream()
      },
      startCameraStream() {
        const constraints = (window.constraints = {
          audio: false,
          video: true,
        })
        navigator.mediaDevices
          .getUserMedia(constraints)
          .then((stream) => {
            this.$refs.camera.srcObject = stream
          })
          .catch((error) => {
            console.error(error)
            this.$message.error('Desculpe mas seu navegador não tem suporte para capturar foto!')
          })
      },
      stopCameraStream() {
        let tracks = this.$refs.camera.srcObject.getTracks()
        tracks.forEach((track) => {
          track.stop()
        })
      },
      //IMPLEMENTANDO ENVIO DE IMAGEM DIRETAMENTE DOS ARQUIVOS DO COMPUTADOR
      resetCamera() {
        this.isCameraOpen = true
        this.startCameraStream()
      },
      openFileDialog() {
        this.$refs.fileInput.click()
      },
      handleFileUpload(event) {
        const file = event.target.files[0]
        if (!file) return

        const reader = new FileReader()
        reader.onload = (e) => {
          this.displayImage(e.target.result)
        }
        reader.readAsDataURL(file)

        event.target.value = null

        this.isCameraOpen = false
      },
      displayImage(dataUrl) {
        const context = this.$refs.canvas.getContext('2d')
        const image = new Image()
        image.onload = () => {
          context.clearRect(0, 0, this.canvasWidth, this.canvasHeight)
          context.drawImage(image, 0, 0, this.canvasWidth, this.canvasHeight)
        }
        image.src = dataUrl
        //FIM
      },
      capture() {
        const FLASH_TIMEOUT = 50
        let self = this
        setTimeout(() => {
          const context = self.$refs.canvas.getContext('2d')
          context.drawImage(self.$refs.camera, 0, 0, self.canvasWidth, self.canvasHeight)
          const dataUrl = self.$refs.canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream')
          self.isCameraOpen = false
          self.stopCameraStream()
        }, FLASH_TIMEOUT)
      },
      limparFoto() {
        this.$swal({
          title: 'Atenção!',
          text: 'Deseja realmente limpar a foto?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Sim!',
          cancelButtonText: 'Não!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-danger ml-1',
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            if (this.dados.id) {
              useJwt
                .delete(`cadastro/tomador/limparFoto`, this.dados.id)
                .then((response) => {
                  this.dados = { ...this.dados, foto: null }
                })
                .catch((error) => {
                  console.error(error)
                })
            } else {
              this.dados = { ...this.dados, foto: null }
            }
          }
        })
      },
      confirmarFoto() {
        this.loading = true
        let self = this
        const dataUrl = self.$refs.canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream')

        if (this.dados.id) {
          useJwt
            .post(`cadastro/tomador/cadastrarFoto`, {
              foto: dataUrl,
              tomadorId: this.dados.id,
            })
            .then((response) => {
              this.dados = { ...this.dados, foto: response.data }
              this.$message.success('Foto capturada com sucesso!')
              this.loading = false
              this.$refs['modal-foto'].hide()
            })
            .catch((error) => {
              this.loading = false
              console.error(error)
            })
        } else {
          this.dados = { ...this.dados, foto: { foto: dataUrl } }
          this.$message.success('Foto capturada com sucesso!')
          this.loading = false
          this.$refs['modal-foto'].hide()
        }
      },
      apresentaCortarFoto() {
        this.$refs['modal-editar-foto'].hide()
      },
      createEditSegurado() {
        this.$refs.formularioSegurado.validate().then((success) => {
          if (success) {
            if (this.segurado.exonerado && this.dados.falecido) {
              this.$message.error('Um tomador falecido não pode ser exonerado!')
              return
            }

            let orgao = undefined
            if (this.segurado.orgao) {
              if (this.segurado.orgao.id) orgao = this.orgao.find((orgao) => orgao.id == this.segurado.orgao.id)
              else orgao = this.orgao.find((orgao) => orgao.id == this.segurado.orgao)
            }
            let dadosFormSegurado = {
              id: this.segurado.id ? this.segurado.id : null,
              matricula: this.segurado.matricula,
              orgao: orgao,
              orgaoId: orgao.id,
              situacaoPrevidenciaria: this.segurado.categoria,
              remuneracao: formatarParaNumber(this.segurado.remuneracao),
              margemUtilizada: formatarParaNumber(this.segurado.margemUtilizada),
              margemDisponivel: formatarParaNumber(this.segurado.margemDisponivel),
              ehVitalicio: this.segurado.ehVitalicio,
              grupoFolhaId: this.segurado.grupoFolhaId,
              tipoBeneficio: this.segurado.tipoBeneficio,
              portadorMolestiaGrave: this.segurado.portadorMolestiaGrave,
              portadorDoencaIncapacitante: this.segurado.portadorDoencaIncapacitante,
              exonerado: this.segurado.exonerado,
              bloqueioJudicial: this.segurado.bloqueioJudicial,

              dataAdmissao: this.segurado.dataAdmissao
                ? String(this.segurado.dataAdmissao).split('/').reverse().join('-')
                : null,
              dataInicioBeneficio: this.segurado.dataInicioBeneficio
                ? String(this.segurado.dataInicioBeneficio).split('/').reverse().join('-')
                : null,
              dataFimBeneficio: this.segurado.dataFimBeneficio
                ? String(this.segurado.dataFimBeneficio).split('/').reverse().join('-')
                : null,
              dataExoneracao: this.segurado.dataExoneracao
                ? String(this.segurado.dataExoneracao).split('/').reverse().join('-')
                : null,
              dataBloqueioJudicial: this.segurado.dataBloqueioJudicial
                ? String(this.segurado.dataBloqueioJudicial).split('/').reverse().join('-')
                : null,
              cargo: this.segurado.cargo,
            }
            // Atualiza essa linha abaixo!!!!!!!!!!!!!
            let nomeTomador = this.dados.nomeCompleto.split(' ')[0]
            // Cadastro de CRP Documento
            if (this.segurado.portariaExoneracao?.name) {
              const formData = new FormData()
              formData.append('nome', 'Portaria Exoneracao - ' + nomeTomador)
              formData.append('seguradoId', dadosFormSegurado.id)
              formData.append('institutoId', this.userData.institutoSelecionado)
              formData.append('file', this.segurado.portariaExoneracao)
              formData.append('fileName', this.segurado.portariaExoneracao.name)

              useJwt
                .postDocumento(`cadastro/tomador/uploadPortariaExoneracao`, () => {}, formData)
                .then((response) => {})
                .catch((error) => {
                  console.error('Erro ao salvar a portaria. Error: ' + error)
                })
            }

            let jaPossuiMatriculaNaGrid = false
            this.tomadorSegurados.forEach((rowSegurado) => {
              if (rowSegurado.matricula === dadosFormSegurado.matricula && rowSegurado.orgaoId === dadosFormSegurado.orgaoId) {
                jaPossuiMatriculaNaGrid = true
              }
            })

            if (!dadosFormSegurado.id && !jaPossuiMatriculaNaGrid) {
              this.tomadorSegurados.push(dadosFormSegurado)
            } else {
              this.tomadorSegurados.forEach((rowSegurado) => {
                if (
                  rowSegurado.id === dadosFormSegurado.id ||
                  (rowSegurado.matricula === dadosFormSegurado.matricula && rowSegurado.orgaoId === dadosFormSegurado.orgaoId)
                ) {
                  rowSegurado.matricula = dadosFormSegurado.matricula
                  rowSegurado.situacaoPrevidenciaria = dadosFormSegurado.situacaoPrevidenciaria
                  rowSegurado.remuneracao = dadosFormSegurado.remuneracao
                  rowSegurado.margemUtilizada = dadosFormSegurado.margemUtilizada
                  rowSegurado.margemDisponivel = dadosFormSegurado.margemDisponivel
                  rowSegurado.ehVitalicio = dadosFormSegurado.ehVitalicio
                  rowSegurado.grupoFolhaId = dadosFormSegurado.grupoFolhaId
                  rowSegurado.dataAdmissao = dadosFormSegurado.dataAdmissao
                  rowSegurado.dataInicioBeneficio = dadosFormSegurado.dataInicioBeneficio
                  rowSegurado.dataFimBeneficio = dadosFormSegurado.dataFimBeneficio
                  rowSegurado.orgaoId = dadosFormSegurado.orgao.id
                  rowSegurado.orgao = dadosFormSegurado.orgao
                  rowSegurado.tipoBeneficio = dadosFormSegurado.tipoBeneficio
                  rowSegurado.exonerado = dadosFormSegurado.exonerado
                  rowSegurado.dataExoneracao = formatarData(dadosFormSegurado.dataExoneracao)
                  rowSegurado.bloqueioJudicial = dadosFormSegurado.bloqueioJudicial
                  rowSegurado.dataBloqueioJudicial = formatarData(dadosFormSegurado.dataBloqueioJudicial)
                  rowSegurado.cargo = dadosFormSegurado.cargo
                }
              })
            }

            if (this.isCreateSegurado) {
              this.$message.info('Salvando...')
              useJwt.put(`cadastro/tomador/updateSegurado/${dadosFormSegurado.id}`, dadosFormSegurado).then((response) => {
                this.editarSegurado(dadosFormSegurado)
                this.$message.success('Segurado alterado com sucesso!')
                this.eraseFormSegurado()
                this.isCreateSegurado = false
              })
            } else {
              //tive que concatenar os obj para ter vinculo do tomador com o segurado na hora da criaçao do segurado
              //anteriormente so cadastrava quando ja existia um seguradoid
              const concatSeguradoTomador = {
                ...dadosFormSegurado,
                tomadorId: this.dados.id,
              }
              useJwt
                .post(`cadastro/tomador/cadastrar-segurado`, concatSeguradoTomador)
                .then((response) => {
                  this.$message.info('Salvando...')
                  this.$message.success('Segurado cadastrado com sucesso!')
                })
                .catch((error) => {
                  this.$message.error('Ocorreu um erro ao cadastrar o segurado')
                })
                .finally(() => {
                  this.isCreateSegurado = false
                  this.eraseFormSegurado()
                })
            }
          }
        })
      },
      eraseFormSegurado() {
        this.segurado.matricula = null
        this.segurado.orgao = null
        this.segurado.categoria = null
        this.segurado.dataAdmissao = null
        this.segurado.dataFimBeneficio = null
        this.segurado.remuneracao = '0.00'
        this.segurado.margemUtilizada = '0.00'
        this.segurado.margemDisponivel = '0.00'
        this.segurado.grupoFolhaId = null
        this.segurado.dataExoneracao = null
        this.segurado.bloqueioJudicial = false
        this.segurado.dataBloqueioJudicial = null
        this.segurado.cargo = null

        if (this.segurado.id) delete this.segurado.id
        this.$refs.formularioSegurado.reset()
      },
      validaDataSegurado(campo) {
        let erros = []

        const dataInicioBeneficio = formatarTipoDate(this.segurado.dataInicioBeneficio)
        const dataNascimento = formatarTipoDate(this.dados.dataNascimento)
        const dataAdmissao = formatarTipoDate(this.segurado.dataAdmissao)
        const categoria = this.segurado.categoria

        // Data de admissão
        if (campo == 'dataAdmissao' && this.segurado.dataAdmissao) {
          if (!this.validarData(this.segurado.dataAdmissao)) erros.push('A data de admissão é inválida!')

          if (
            categoria == 'Pensionista' &&
            this.segurado.dataAdmissao > this.segurado.dataFimBeneficio &&
            this.segurado.dataFimBeneficio
          ) {
            erros.push('A data de admissão não pode ser superior a data fim do benefício!')
            this.segurado.dataAdmissao = ''
          }
          if (dataAdmissao < dataNascimento) {
            erros.push('A data de admissão não pode ser inferior a data de nascimento do tomador!')
            this.segurado.dataAdmissao = ''
          }
        }
        //  Data de início do benefício
        if (campo == 'dataInicioBeneficio' && this.segurado.dataInicioBeneficio) {
          if (!this.validarData(this.segurado.dataInicioBeneficio)) erros.push('A data início do benefício é inválida!')
          if (dataInicioBeneficio < dataNascimento) {
            erros.push('A data início do benefício não pode ser inferior a data de nascimento!')
            this.segurado.dataInicioBeneficio = ''
          }
        }

        // Data de emissão do RG
        if (campo == 'rgDataEmissao' && this.dados.rgDataEmissao) {
          if (!this.validarData(this.dados.rgDataEmissao)) erros.push('A data emissão do RG  é inválida!')

          if (this.dados.rgDataEmissao < dataNascimento) {
            erros.push('A data emissão do RG não pode ser inferior a data de nascimento!')
            this.dados.rgDataEmissao = ''
          }
        }

        // Data de fim do benefício
        if (campo == 'dataFimBeneficio' && this.segurado.dataFimBeneficio) {
          if (!this.validarData(this.segurado.dataFimBeneficio)) erros.push('A data fim do benefício é inválida!')
          if (formatarDataGlobal(this.segurado.dataFimBeneficio) < formatarDataGlobal(this.segurado.dataInicioBeneficio)) {
            erros.push('A data fim do benefício não pode ser inferior a data de inicio do benefício!')
            this.segurado.dataFimBeneficio = ''
          }
          if (formatarDataGlobal(this.segurado.dataFimBeneficio) < formatarDataGlobal(dataNascimento)) {
            erros.push('A data fim do benefício não pode ser inferior a data de nascimento!')
            this.segurado.dataFimBeneficio = ''
          }
        }

        // Data de óbito
        if (campo == 'dataObito' && this.dados.dataObito) {
          if (!this.validarData(this.dados.dataObito)) erros.push('A data de óbito é inválida!')
          if (this.dados.dataNascimento < this.dados.dataObito) {
            erros.push('A data de óbito não pode ser inferior a data de nascimento!')
            this.dados.dataObito = ''
          }
          if (this.dados.dataObito < this.dados.dataInicioBeneficio) {
            erros.push('A data de óbito não pode ser inferior a data de início do benefício!')
            this.dados.dataObito = ''
          }
        }
        //Data bloqueio judicial
        if (campo == 'dataBloqueioJudicial') {
          if (!this.validarData(this.segurado.dataBloqueioJudicial)) erros.push('A data de bloqueio judicial é inválida!')
        }
        if (erros?.length > 0) {
          erros.forEach((erro) => {
            this.$message.error(erro)
          })
        }
      },
      validaCamposDocumentos(campo, id) {
        let erros = []

        switch (id) {
          case 'rgNumero':
            if (campo?.length > 0 && !validaRG(campo)) {
              erros.push('RG inválido.')
              this.dados.rgNumero = ''
            }
            break
          case 'cpf':
            if (campo?.length > 0 && !isCPFValid(campo)) {
              erros.push('CPF inválido.')
              this.dados.cpf = ''
            }
            break
          case 'chavePixCPF':
            if (campo && !isCPFValid(campo)) {
              erros.push('CPF do pix inválido.')
              this.dados.chavePix = ''
            }
            break
          case 'chavePixCNPJ':
            if (campo && !validarCnpj(campo)) {
              erros.push('CNPJ do pix inválido.')
              this.dados.chavePix = ''
            }
            break
          case 'chavePixEMAIL':
            if (campo && !validarEmail(campo)) {
              erros.push('E-mail do pix inválido.')
              this.dados.chavePix = ''
            }
            break
          case 'chavePixTELEFONE':
            if (campo && !validarTelefone(campo)) {
              erros.push('Telefone do pix inválido.')
              this.dados.chavePix = ''
            }
            break
          default:
            break
        }
        if (erros?.length > 0) {
          erros.forEach((erro) => {
            this.$message.error(erro)
          })
        }
      },
      editarSegurado(segurado) {
        let remuneracao = !segurado.remuneracao
          ? '0.00'
          : String(segurado.remuneracao).includes('.')
          ? Number(segurado.remuneracao)
          : Number(segurado.remuneracao) * 100
        let margemUtilizada = !segurado.margemUtilizada
          ? '0.00'
          : String(segurado.margemUtilizada).includes('.')
          ? Number(segurado.margemUtilizada)
          : Number(segurado.margemUtilizada) * 100
        let margemDisponivel = !segurado.margemDisponivel
          ? '0.00'
          : String(segurado.margemDisponivel).includes('.')
          ? Number(segurado.margemDisponivel)
          : Number(segurado.margemDisponivel) * 100

        let formSegurado = {
          matricula: segurado.matricula,
          orgao: segurado.orgao,
          categoria: segurado.situacaoPrevidenciaria,
          remuneracao: remuneracao,
          margemUtilizada: margemUtilizada,
          margemDisponivel: margemDisponivel,
          ehVitalicio: segurado.ehVitalicio ? segurado.ehVitalicio : false,
          grupoFolhaId: segurado.grupoFolhaId,
          tipoBeneficio: segurado.tipoBeneficio,
          exonerado: segurado.exonerado ? segurado.exonerado : false,
          portariaExoneracao: segurado.portariaExoneracao,
          bloqueioJudicial: segurado.bloqueioJudicial,
          dataAdmissao: segurado.dataAdmissao ? formatarData(segurado.dataAdmissao) : null,
          dataExoneracao: segurado.dataExoneracao ? formatarData(segurado.dataExoneracao) : null,
          dataInicioBeneficio: segurado.dataInicioBeneficio ? formatarData(segurado.dataInicioBeneficio) : null,
          dataFimBeneficio: segurado.dataFimBeneficio ? formatarData(segurado.dataFimBeneficio) : null,
          dataBloqueioJudicial: segurado.dataBloqueioJudicial ? formatarData(segurado.dataBloqueioJudicial) : null,
          cargo: segurado.cargo,
        }

        // Buscar dados da portaria para preencher a variável para download
        if (segurado.exonerado && segurado.id) {
          useJwt
            .get(`cadastro/tomador/GetPortariaExoneracaoSegurado/${segurado.id}`)
            .then((response) => {
              this.portariaExoneracaoAnexada = response.data
            })
            .catch((error) => {
              console.error(error)
            })
        }

        if (segurado.id) formSegurado['id'] = segurado.id

        this.segurado = formSegurado
        this.isCreateSegurado = true

        this.carregarGrupoFolha()
      },
      excluirSegurado(segurado) {
        const parametros = {
          seguradoId: segurado,
          institutoId: this.dados.institutoSelecionado,
        }

        useJwt
          .post('cadastro/tomador/GetPodeExcluirSegurado', parametros)
          .then((response) => {
            const { propostaQuantidade } = response.data
            const { simulacaoQuantidade } = response.data
            if (propostaQuantidade > 0 || simulacaoQuantidade > 0) {
              const simulacoes = `${simulacaoQuantidade} simulação(ões)`
              const propostas = `${propostaQuantidade} proposta(s)`
              const ligacao = propostaQuantidade > 0 && simulacaoQuantidade > 0 ? 'e' : ''
              const messagem = `Esse segurado já está associado à ${simulacaoQuantidade > 0 ? simulacoes : ''} ${ligacao} ${
                propostaQuantidade > 0 ? propostas : ''
              }`
              this.$message.error(messagem)
              return
            }

            if (segurado) {
              this.excluirSegurados.push(segurado)
              this.dados.excluirSegurados = this.excluirSegurados
            }

            this.tomadorSegurados = this.tomadorSegurados.filter((rowSegurado) => rowSegurado.id != segurado)
            this.dados.segurados = this.tomadorSegurados
          })
          .catch((error) => {
            console.error(error)
            this.$message.error('Não foi possível excluir segurado!')
          })
      },
      preencheMargemDisponivel() {
        const remuneracao = formatarParaNumber(this.segurado.remuneracao)
        if (isNumber(remuneracao)) {
          const valorRemuneracao = remuneracao
          const porcentagemMargemParametrizada = Number(Number(this.userData.Instituto.margemConsignavel) / 100).toFixed(2)
          this.segurado.margemDisponivel = Number(valorRemuneracao * porcentagemMargemParametrizada).toFixed(2)
        }
      },
      limpaGrupoFolha() {
        this.segurado.grupoFolhaId = null
      },
      insereCertidaoObito() {
        const formData = new FormData()
        const nomeTomador = String(this.dados.nomeCompleto).split(' ')[0]

        formData.append('nome', 'CertidaoObito - ' + nomeTomador)
        formData.append('fileName', 'CertidaoObito - ' + nomeTomador)
        formData.append('institutoId', this.userData.institutoSelecionado)
        if (this.dados.id) formData.append('tomadorId', this.dados.id)
        let file = this.$refs.certidaoObitoFile.files[0]
        formData.append('file', file)

        useJwt
          .postDocumento(`cadastro/tomador/uploadCertidaoObito`, (event) => {}, formData)
          .then((response) => {})
          .catch((error) => {
            console.error(error)
            this.$message.error(String(error))
          })
      },
      inserePortariaExoneracao() {
        const formData = new FormData()
        const nomeTomador = String(this.dados.nomeCompleto).split(' ')[0]

        formData.append('nome', 'Portaria de Exoneração - ' + nomeTomador)
        formData.append('fileName', 'Portaria de Exoneração - ' + nomeTomador)
        formData.append('institutoId', this.userData.institutoSelecionado)
        if (this.dados.id) formData.append('tomadorId', this.dados.id)
        let file = this.$refs.certidaoObitoFile.files[0]
        formData.append('file', file)

        useJwt
          .postDocumento(`cadastro/tomador/uploadCertidaoObito`, (event) => {}, formData)
          .then((response) => {})
          .catch((error) => {
            console.error(error)
            this.$message.error(String(error))
          })
      },
      async downloadPortariaExoneracaoAnexada() {
        const fileName = 'Portaria de Exoneração.pdf'
        const s3Key = this.portariaExoneracaoAnexada.s3Key
        this.isBusy = true
        await downloadFileAWS(s3Key, fileName)
          .then((response) => {
            if (response) {
              this.$message.success('Download realizado com sucesso!')
            }
          })
          .catch((error) => {
            console.error(error)
            this.$message.error('Erro ao realizar o download!')
          })
          .finally(() => {
            this.isBusy = false
          })
      },
      excluirPortariaExoneracaoAnexada() {
        if (this.portariaExoneracaoAnexada && this.portariaExoneracaoAnexada.id) {
          this.portariaExoneracaoAnexada.id = null
        }
      },
      anexarArquivo() {
        this.$refs['modal-enviar-documento'].show()
      },
      async downloadArquivo(item) {
        await getDownloadArquivoAws(item.s3Key)
      },
      excluirArquivo(arquivoId) {
        this.isBusyArquivos = true
        arquivoId = formatarParaNumber(arquivoId)
        useJwt
          .delete('/aws/excluirArquivoAWS', arquivoId)
          .then((response) => {
            this.$message.success('Arquivo excluído com sucesso!')
            this.carregarGridArquivos()
          })
          .catch((error) => {
            console.error(error)
            this.carregarGridArquivos()
            this.$message.error('Erro ao excluir o arquivo!')
          })
          .finally(() => {
            this.isBusyArquivos = true
          })
      },
      async carregarGridArquivos() {
        this.isBusyArquivos = true
        if (this.parametro.id)
          useJwt
            .get(`cadastro/tomador/GetDocumentosTomador/${this.parametro.id}`)
            .then((response) => {
              this.itemsArquivos = response.data
            })
            .catch((error) => {
              console.error(error)
            })
            .finally(() => {
              this.isBusyArquivos = false
            })
      },
      fecharModalUploadArquivo() {
        this.$refs['modal-enviar-documento'].hide()
        this.carregarGridArquivos()
      },
      formatarCategoriaSegurado(categoria) {
        switch (categoria) {
          case 'aposentadoriaPorIncapacidadePermanente':
            return 'Aposentadoria por Incapacidade Permanente'
          case 'aposentadoriaPorTempoDeContribuição':
            return 'Aposentadoria por Tempo de Contribuição'
          case 'aposentadoriaCompulsoria':
            return 'Aposentadoria Compulsória'
          case 'aposentadoriaPorIdade':
            return 'Aposentadoria por Idade'
          case 'aposentadoriaPorTempoDeServico':
            return 'Aposentadoria por Tempo de Serviço'
          default:
            return categoria
        }
      },
      limparCargo() {
        this.segurado.cargo = '' // Limpa o campo cargo quando a categoria mudar
      },
    },
    watch: {
      'segurado.ehVitalicio': function (val) {
        if (val) {
          this.segurado.dataFimBeneficio = null
        }
      },
      segurado: {
        handler(newVal) {
          if (newVal.categoria) {
            if (newVal.categoria === 'Pensionista') {
              this.segurado.cargo = this.segurado.categoria
            } else if (newVal.categoria === 'Aposentado') {
              this.segurado.cargo = this.segurado.tipoBeneficio
            }
          }
        },
        deep: true,
      },
    },
  }
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  .camera-canvas {
    text-align: center;
  }

  #modal-foto___BV_modal_footer_ {
    margin-left: auto;
    left: 0px;
    right: 0px;
    margin-right: auto;
  }

  label[for='btnCreateEditSegurado'],
  label[for='btnEraseFormSegurado'] {
    height: 17.39px;
  }

  [dir] body.dark-layout #portadorDoencaIncapacitante > label.btn.btn-outline-primary.active > span,
  [dir] body.dark-layout #portadorMolestiaGrave > label.btn.btn-outline-primary.active > span,
  [dir] body.dark-layout #falecido > label.btn.btn-outline-primary.active > span,
  [dir] body.dark-layout #exonerado > label.btn.btn-outline-primary.active > span {
    color: #ecf0f1;
  }

  .breakWord {
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
  }

  .flexRowReverse {
    align-items: center;
    display: flex;
    flex-direction: row-reverse;
  }

  .table-overflow {
    overflow-x: auto;
    max-width: 100%;
  }
</style>
