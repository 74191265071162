import { parse, isValid, set } from 'date-fns'

/**
 * Converte uma string em um valor numérico.
 *
 * @param {string} campo - A string a ser convertida.
 * @returns {string} - O valor numérico sem caracteres não numéricos.
 * @throws {TypeError} - Se a entrada não for uma string.
 */
export function toNumeric(campo: string): string {
  if (typeof campo !== 'string') {
    throw new TypeError('A entrada deve ser uma string')
  }

  return campo.replace(/[^\d]/g, '')
}

/**
 * Formata um valor como uma string de valor em Real Brasileiro (BRL).
 *
 * @param {number | string | null | undefined} value - O valor a ser formatado. Pode ser um número,
 * uma string, nulo ou indefinido.
 * @returns {string | null} - O valor formatado como uma string de valor. Retorna nulo se a entrada for
 * inválida ou nula ou indefinida.
 */
export function formatarValor(value: number | string | null | undefined): string | null {
  if (value === null || value === undefined) {
    return null
  }

  if (typeof value === 'string' && value.includes('.')) {
    const parsedValue = parseFloat(value.replace(',', '.'))
    if (isNaN(parsedValue) || parsedValue <= 0) {
      return null
    }
  }

  if (isNaN(Number(value))) {
    return null
  }
  let numericValue: number

  if (typeof value === 'number') {
    numericValue = value
  } else if (typeof value === 'string') {
    const parsedValue = parseFloat(value.replace(',', '.'))
    if (isNaN(parsedValue) || parsedValue <= 0) {
      return null
    }
    numericValue = parsedValue
  } else {
    return null
  }

  return `R$ ${numericValue.toLocaleString('pt-BR', { minimumFractionDigits: 2 })}`
}
/**
 * Formata um valor como uma string de porcentagem.
 *
 * @param {number | string | null | undefined} value - O valor a ser formatado. Pode ser um número,
 * uma string, nulo ou indefinido.
 * @returns {string | null} - O valor formatado como uma string de porcentagem. Retorna 'Entrada inválida'
 * se a entrada for inválida ou nula ou indefinida.
 * @throws {Error} - Lança um erro se o valor não for um número ou uma string.
 */
export function formatarValorPorcentagem(value: number | string | null | undefined): string | null {
  if (
    value === null ||
    value === undefined ||
    isNaN(Number(value)) ||
    (typeof value !== 'number' && typeof value !== 'string')
  ) {
    throw new Error('Entrada inválida')
  }

  if (typeof value === 'number') {
    value = value.toFixed(2)
  } else if (typeof value === 'string') {
    value = parseFloat(value).toFixed(2)
  }

  return value.replace('.', ',') + '%'
}

/**
 * Formata uma data como uma string com o formato 'dd/MM/yyyy'.
 *
 * @param {string | Date | null | undefined} date - A data a ser formatada. Pode ser uma string no formato
 * yyyy-MM-dd, uma instância de Date ou nulo ou indefinido.
 * @returns {string} - A data formatada como uma string no formato 'dd/MM/yyyy'. Retorna uma string vazia se a entrada
 * for inválida ou nula ou indefinida.
 * @throws {Error} - Lança um erro se a data não for uma string no formato yyyy-MM-dd nem uma instância de Date.
 */
export function formatarData(date: string | Date | null | undefined): string {
  // Validação da entrada
  if (!date) {
    return ''
  }

  if (typeof date !== 'string' && !(date instanceof Date)) {
    throw new Error('Entrada inválida. A data deve ser uma string no formato yyyy-MM-dd ou uma instância de Date.')
  }

  try {
    // Conversão da data para o formato desejado
    return new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(new Date(date))
  } catch (error) {
    console.error(`Erro ao converter a data: ${date}\n Erro: ${error}`)
    return ''
  }
}
/**
 * Formata uma data como uma string no formato 'Mês/Ano'.
 *
 * @param {Date} data - A data a ser formatada.
 * @returns {string} - A data formatada como uma string no formato 'Mês/Ano'.
 * @throws {Error} - Lança um erro se a data não for uma instância de Date.
 */
export function formatarCompetencia(data: Date): string {
  // Validação da entrada
  if (!(data instanceof Date)) {
    throw new Error('Entrada inválida. A data deve ser uma instância de Date.')
  }

  const meses = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ]

  const mes = data.getMonth()
  const ano = data.getFullYear()

  // Validação do mês
  if (mes < 0 || mes > 11) {
    throw new Error('Mês inválido')
  }

  // Formata a data
  return `${meses[mes]}/${ano}`
}

/**
 * Formata uma data e hora como uma string no formato 'Dia/Mês/Ano Hora:Minuto'.
 *
 * @param {string | number | Date} date - A data a ser formatada.
 * @returns {string} - A data formatada como uma string no formato 'Dia/Mês/Ano Hora:Minuto'.
 * @throws {Error} - Lança um erro se a data não for uma instância válida de Date.
 */
export function formatarDataHora(date: string | number | Date): string {
  // Validação da entrada
  const dateObj = new Date(date)
  if (isNaN(dateObj.getTime())) {
    throw new Error(
      'Entrada inválida. A data deve ser uma instância de Date, um número ou uma string no formato yyyy-MM-ddTHH:mm:ss.',
    )
  }
  const formatter = new Intl.DateTimeFormat('pt-BR', {
    timeZone: 'America/Cuiaba',
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  })

  return formatter.format(dateObj)
}
/**
 *
 * @param {string | null} cat - O valor a ser formatado.
 * @returns {string | null} - O valor formatado ou nulo caso o valor inicial seja nulo.
 */
/**
 *
 * @param {string | null} cat - O valor a ser formatado.
 * @returns {string | null} - O valor formatado ou nulo caso o valor inicial seja nulo.
 * @throws {Error} - Caso o valor não seja 'Aposentado' nem nulo.
 */
export function formatarAposentadoInativo(cat: string | null): string | null {
  if (!cat) return null
  if (cat !== 'Aposentado' && cat !== null) {
    throw new Error('Entrada inválida. O valor deve ser "Aposentado" ou nulo.')
  }
  return cat
}
/**
 * Formata o valor para o formato de moeda brasileira
 *
 * @param {number | string | null} valor - O valor a ser formatado.
 * @returns {string | null} - O valor formatado ou nulo caso o valor inicial seja nulo.
 * @throws {TypeError} - Caso o valor não seja um número, uma string representando um número ou nulo.
 */
export function formatarValorReais(valor: number | string | null): string | null {
  if (valor === null) {
    return null
  }

  // Tenta converter strings que representem números em números reais
  if (typeof valor === 'string') {
    valor = Number(valor)
  }

  // Verifica se o valor é um número após possível conversão
  if (typeof valor !== 'number' || isNaN(valor)) {
    throw new TypeError('O valor deve ser um número, uma string representando um número ou nulo.')
  }

  // Formatar o valor para o formato de moeda brasileira
  return new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(valor)
}

/**
 * Formata a competência em uma string de descrição no formato Mês/Ano.
 *
 * @param {string} competencia - A competência a ser formatada no formato 'YYYYMM'.
 * @returns {string} A descrição da competência no formato 'Mês/Ano'.
 * @throws {Error} Se a competência não for uma string de 6 caracteres.
 * @throws {Error} Se a competência não for um formato válido 'YYYYMM'.
 */
export function formatarCompetenciaDescricao(competencia: string): string {
  // Validação da entrada
  if (typeof competencia !== 'string' || competencia.length !== 6) {
    throw new Error('A competência deve ser uma string de 6 caracteres no formato "YYYYMM".')
  }
  if (!/^\d{6}$/.test(competencia)) {
    throw new Error('A competência deve ser um formato válido "YYYYMM".')
  }

  const ano = competencia.slice(0, 4)
  const mes = competencia.slice(4) as '01' | '02' | '03' | '04' | '05' | '06' | '07' | '08' | '09' | '10' | '11' | '12'

  let mesStr: string
  switch (mes) {
    case '01':
      mesStr = 'Janeiro'
      break
    case '02':
      mesStr = 'Fevereiro'
      break
    case '03':
      mesStr = 'Março'
      break
    case '04':
      mesStr = 'Abril'
      break
    case '05':
      mesStr = 'Maio'
      break
    case '06':
      mesStr = 'Junho'
      break
    case '07':
      mesStr = 'Julho'
      break
    case '08':
      mesStr = 'Agosto'
      break
    case '09':
      mesStr = 'Setembro'
      break
    case '10':
      mesStr = 'Outubro'
      break
    case '11':
      mesStr = 'Novembro'
      break
    case '12':
      mesStr = 'Dezembro'
      break
    default:
      throw new Error('A competência deve ser um formato válido "YYYYMM".')
  }

  return `${mesStr}/${ano}`
}

/**
 * Formats a global date string into a JavaScript Date object.
 * If the input date is already a valid Date object, it is returned as is.
 *
 * @param {string | Date} date - The date string to be formatted.
 * @returns {Date | null} The formatted Date object or null if the input is invalid.
 * @throws {Error} If the input date is not a valid string or Date object.
 */
export function formatarDataGlobal(date: string | Date): Date | null {
  // Validate input
  if (!date) return null
  if (date instanceof Date && isValid(date)) return date
  if (typeof date !== 'string') throw new Error('Input date must be a string or Date object.')

  try {
    // Parse and return the formatted date
    return new Date(parse(date, 'dd/MM/yyyy', new Date()))
  } catch (error) {
    console.error(error)
    throw new Error('Invalid date format.')
  }
}

/**
 * Formata um código para uma string de 6 dígitos com zeros à esquerda.
 * Se a entrada não for um número válido, um erro é lançado.
 *
 * @param {number} código - O código a ser formatado.
 * @throws {Error} Se a entrada não for um número válido.
 * @returns {string} O código formatado como uma string de 6 dígitos com zeros à esquerda.
 */
export function formatarCodigoSequencial(código: number): string {
  if (typeof código !== 'number' || isNaN(código) || código < 0 || !Number.isInteger(código)) {
    throw new Error('O código de entrada deve ser um número válido.')
  }

  return código.toString().padStart(6, '0')
}

/**
 * Formata um valor para um número de ponto flutuante.
 * Se o valor de entrada não for um número válido, um erro é lançado.
 *
 * @param {string | number | null | undefined} valor - O valor a ser formatado.
 * @throws {Error} Se o valor de entrada não for um número válido.
 * @returns {number | null} O número formatado ou null se o valor de entrada for inválido.
 */
export function formatarParseFloat(valor: number | string | null | undefined): number | null {
  if (typeof valor === 'number') return valor

  if (valor === null || valor === undefined) return null

  if (typeof valor !== 'string') {
    throw new Error('O valor de entrada deve ser uma string, número, nulo ou indefinido.')
  }

  let valorLimpo = valor.trim().replace(/[^\d,.-]/g, '')

  if (valorLimpo === '') return null

  if (valorLimpo.includes(',')) {
    if (valorLimpo.match(/,\d{2}$/)) {
      valorLimpo = valorLimpo.replace(/\./g, '').replace(',', '.')
    } else {
      valorLimpo = valorLimpo.replace(/,/g, '')
    }
  }

  const parsedValue = parseFloat(valorLimpo)

  if (isNaN(parsedValue)) {
    throw new Error('O valor de entrada deve ser um número válido.')
  }

  return parsedValue
}

/**
 * Formata um valor para uma string no formato local.
 * A string retornada é formatada com duas casas decimais,
 * usando o padrão de formatação do Brasil.
 *
 * @param {number | null | undefined} valor - O valor a ser formatado.
 * Pode ser um número, nulo ou indefinido.
 * @throws {Error} Se o valor de entrada não for um número válido.
 * @returns {string | null} A string formatada ou null se o valor de entrada for inválido.
 */
export function formatarToLocaleString(valor: number | null | undefined): string | null {
  if (valor === null || valor === undefined) {
    return null
  }

  if (typeof valor !== 'number' || Number.isNaN(valor)) {
    throw new Error('O valor de entrada deve ser um número válido, não nulo nem indefinido.')
  }

  return valor.toLocaleString('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
}

/**
 * Formata uma data e hora para uma string no formato local.
 * A string retornada é formatada com o estilo de data longo e o estilo de hora do Brasil.
 *
 * @param {Date | string | null | undefined} date - A data a ser formatada.
 * Pode ser uma instância de Date, uma string no formato ISO, nulo ou indefinido.
 * @throws {Error} Se a data de entrada não for válida.
 * @returns {string | null} A string formatada ou null se a data de entrada for inválida.
 */
export function formatarDataWTime(date: Date | string | null | undefined): string | null {
  if (date === null || date === undefined) {
    return null
  }

  if (typeof date === 'string') {
    date = new Date(date)
  }

  if (isNaN(date.getTime())) {
    throw new Error('A data de entrada deve ser uma instância de Date válida ou uma string no formato ISO.')
  }

  return `${date.toLocaleDateString('pt-BR', { dateStyle: 'long' })} ${date.toLocaleTimeString('pt-BR')}`
}

/**
 * Formata uma data para uma string no formato curto.
 * A string retornada é formatada com o dia, mês e ano, separados por '/'.
 *
 * @param {Date | string | null | undefined} data - A data a ser formatada.
 * Pode ser uma instância de Date, uma string no formato ISO, nulo ou indefinido.
 * @returns {string} A string formatada no formato curto ou uma string vazia se a data de entrada for inválida.
 * @throws {Error} Se a data de entrada não for uma instância de Date válida ou uma string no formato ISO.
 */
export function formatarDataCurto(data: Date | string | null | undefined): string {
  if (!data) {
    return ''
  }

  let date: Date
  if (typeof data === 'string') {
    // Assume que a entrada de string é uma data ISO e cria a data em UTC
    date = new Date(data)
    if (isNaN(date.getTime())) {
      throw new Error('Não foi possível converter a string para uma data válida.')
    }
  } else if (data instanceof Date) {
    // Cria uma nova data em UTC com base na data fornecida
    date = new Date(
      Date.UTC(data.getUTCFullYear(), data.getUTCMonth(), data.getUTCDate(), data.getUTCHours(), data.getUTCMinutes()),
    )
    if (isNaN(date.getTime())) {
      throw new Error('A instância de Date fornecida é inválida.')
    }
  } else {
    return ''
  }

  // Função para adicionar zero à esquerda para dias e meses menores que 10
  const padZero = (num: number) => num.toString().padStart(2, '0')

  // Retorna a data no formato dd/mm/yyyy usando métodos UTC para evitar problemas de fuso horário
  return `${padZero(date.getUTCDate())}/${padZero(date.getUTCMonth() + 1)}/${date.getUTCFullYear()}`
}

/**
 * Converte uma entrada em um número.
 *
 * @param {null | undefined | string | number} entrada - A entrada a ser convertida.
 * @returns {null | undefined | number} O número convertido ou a mesma entrada se não for possível a conversão.
 * @throws {Error} Se a entrada não for um número, uma string válida ou nula.
 */
export function formatarParaNumber(entrada: null | undefined | string | number): null | undefined | number {
  if (entrada === null || entrada === undefined) {
    return entrada as null | undefined
  }

  if (typeof entrada === 'number') {
    return entrada
  }

  if (typeof entrada === 'string') {
    // Primeiro, remover caracteres não relevantes
    let valorLimpo = entrada.replace(/[^\d,.-]/g, '')

    // Tratamento especial para percentuais
    if (valorLimpo.includes('%')) {
      valorLimpo = valorLimpo.replace(/%/g, '')
      const percentual = parseFloat(valorLimpo.replace(',', '.'))
      if (isNaN(percentual)) {
        throw new Error('A entrada não é um número válido.')
      }
      return percentual / 100
    }

    // Substituir primeiro as vírgulas por pontos
    valorLimpo = valorLimpo.replace(/,/g, '.')

    // Remover pontos duplicados que poderiam ter sido separadores de milhar
    const partes = valorLimpo.split('.')
    if (partes.length > 2) {
      valorLimpo = partes.shift() + '.' + partes.join('')
    }

    if (!(valorLimpo.charAt(0) === '-')) {
      valorLimpo = valorLimpo.replace('-', '')
    }

    const convertedValue = parseFloat(valorLimpo)
    if (isNaN(convertedValue)) {
      throw new Error('A entrada não é um número válido.')
    }

    return convertedValue
  }

  throw new Error('A entrada precisa ser um número, uma string válida ou nula.')
}

/**
 * Formata um CPF como uma string no formato 'xxx.xxx.xxx-xx'.
 *
 * @param {string} cpf - O CPF a ser formatado.
 * @returns {string} O CPF formatado seguindo o padrão 'xxx.xxx.xxx-xx'.
 * @throws {Error} Se o CPF não for uma string válida.
 */
export function formatarCPF(cpf: string): string {
  if (typeof cpf !== 'string') {
    throw new Error('O CPF precisa ser uma string.')
  }

  const cpfSemMascara = cpf.replace(/[^\d]/g, '')

  if (cpfSemMascara.length !== 11) {
    throw new Error('O CPF deve ter 11 dígitos.')
  }

  return cpfSemMascara.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, '$1.$2.$3-$4')
}

/**
 * Formata uma string de data no formato 'dd/mm/yyyy' em um objeto Date.
 *
 * @param {string} data - A string de data a ser formatada.
 * @returns {Date} O objeto Date correspondente à data fornecida.
 * @throws {Error} Se a data fornecida não for uma string válida.
 */
export function formatarTipoDate(data: string): Date {
  if (typeof data !== 'string') {
    throw new Error('A data precisa ser uma string.')
  }

  // Supondo que a função 'formatarData' retorna a data no formato 'dd/mm/yyyy'
  const dataLista = data.split('/')

  // Validação da data
  const dia = Number(dataLista[0])
  const mes = Number(dataLista[1])
  const ano = Number(dataLista[2])
  if (isNaN(dia) || isNaN(mes) || isNaN(ano) || dia < 1 || dia > 31 || mes < 1 || mes > 12 || ano < 1 || ano > 9999) {
    throw new Error('A data fornecida não é válida.')
  }

  // Criação da data em UTC
  return new Date(Date.UTC(ano, mes - 1, dia))
}

/**
 * Função que transforma uma string de data no formato ISO ou um objeto Date no formato "dd de Mês de yyyy".
 * Valida se a data é válida e converte strings para objetos Date.
 *
 * @param {string | Date} input - Data no formato string ISO ou objeto Date.
 * @returns {string} - Retorna a data no formato "1 de Janeiro de 2024".
 * @throws {Error} - Lança um erro se o valor passado não for uma data válida.
 */
export function formatarDataExtensa(input: string | Date): string {
  let date: Date

  if (typeof input === 'string') {
    date = new Date(input)
  } else if (input instanceof Date) {
    date = input
  } else {
    throw new Error('Formato de data inválido fornecido.')
  }

  if (isNaN(date.getTime())) {
    throw new Error('Data inválida fornecida. Por favor, forneça um objeto Date válido.')
  }

  const day = date.getDate()
  const month = date.toLocaleString('pt-BR', { month: 'long' })
  const year = date.getFullYear()

  const formattedMonth = month.charAt(0).toUpperCase() + month.slice(1)

  return `${day} de ${formattedMonth} de ${year}`
}

/**
 * Normaliza o nome de um arquivo, removendo acentuações e caracteres especiais.
 * Transforma, por exemplo, 'ação' em 'acao'.
 *
 * @param {string} fileName - O nome do arquivo a ser normalizado.
 * @returns {string} - O nome do arquivo normalizado sem acentos.
 * @throws {Error} - Lança um erro se o parâmetro de entrada não for uma string.
 */
export function normalizeFileName(fileName: string): string {
  if (typeof fileName !== 'string') {
    throw new Error('O parâmetro deve ser uma string')
  }

  return fileName.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
}

/**
 * Formata um RG no padrão 'xxxxxxx-x', onde o último caractere é o dígito verificador.
 * Valida se o RG tem entre 7 e 9 dígitos.
 *
 * @param {string | number} rg - O RG a ser formatado.
 * @returns {string} - O RG formatado.
 * @throws {Error} - Lança um erro se o parâmetro de entrada não for uma string ou um número
 * ou se o RG tiver menos de 7 ou mais de 9 dígitos.
 */
export function formatToRg(rg: string | number): string {
  if (typeof rg !== 'string' && typeof rg !== 'number') {
    throw new Error('O parâmetro deve ser uma string ou um número')
  }

  const rgString = rg.toString()

  if (!/^\d{7,9}$/.test(rgString)) {
    throw new Error('O RG deve conter entre 7 e 9 dígitos')
  }

  // Formata o RG no padrão 'xxxxxxx-x'
  const base = rgString.slice(0, -1)
  const digitoVerificador = rgString.slice(-1)
  return `${base}-${digitoVerificador}`
}
